import { Layout, Space, Button } from "antd";
import { Colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import "../HeaderLandingPage/index";
import {
  MenuOutlined,
  CloseOutlined,
  ReadOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import WalletButton from "../WalletButton";
import LangButton from "../LangButton";
import { useTranslation } from "react-i18next";
import LaunchButton from "../LaunchButton";
const { Header } = Layout;

export const HeaderStaking = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [showNav, setNav] = useState(false);

  const params = document.location.search;

  const onClickLogo = () => {
    navigate("/");
  };

  const pathName = window.location.pathname;
  // @ts-ignore
  let ver_name = window._zbc_app_ver_name;

  // @ts-ignore
  let ver = window._zbc_app_ver;

  const onload = function () {
    let ver_string = window.location.pathname.split("/");
    let v = ver_string[1] === "zbc-u" ? "u" : "z";
    // console.log(ver, v, ver === v);
    if (ver !== v) {
      // wallet.disconnect();
      window.location.reload();
    }
  };

  // check if we on the right ver
  useEffect(() => {
    onload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header>
      <Space className="my-nav">
        <div style={{ width: "100vw" }}>
          <i onClick={onClickLogo} className="logo"></i>
          <div className="lang-btn">
            <LangButton />
          </div>
          {showNav ? (
            <CloseOutlined
              onClick={() => setNav(!showNav)}
              className="m-handler"
            />
          ) : (
            <MenuOutlined
              onClick={() => setNav(!showNav)}
              className="m-handler"
            />
          )}
        </div>
        <Space className={`nav-dropdown ${showNav ? "show-nav" : ""}`}>
          <div className="lang-btn-pc">
            <LangButton />
          </div>
          <LaunchButton>
            <Button type="primary">
              <SwapOutlined />

              <span>{ver_name}</span>
            </Button>
          </LaunchButton>
          <Button
            // href="/staking"
            onClick={() => navigate("/" + ver_name + "/staking"+params)}
            style={
              pathName === "/" + ver_name + "/staking" ? linkActive : linkStyle
            }
            type="link"
          >
            {t("Stake")}
          </Button>
          <Button
            // href="claim"
            onClick={() => navigate("/" + ver_name + "/claim"+params)}
            style={
              pathName === "/" + ver_name + "/claim" ? linkActive : linkStyle
            }
            type="link"
          >
            {t("Claim")}
          </Button>
          <Button
            // href="/refer"
            onClick={() => navigate("/" + ver_name + "/refer"+params)}
            style={
              pathName === "/" + ver_name + "/refer" ? linkActive : linkStyle
            }
            type="link"
          >
            {t("Refer")}
          </Button>

          <Button onClick={() => window.open("https://docs.zbcstaking.com/")}>
            <ReadOutlined />
            <span>Guide</span>
          </Button>
          <Space className="wallet-button" style={{ marginLeft: "15px" }}>
            <WalletButton />
          </Space>
        </Space>
      </Space>
    </Header>
  );
};

const linkStyle: React.CSSProperties = {
  color: Colors.white,
};

const linkActive: React.CSSProperties = {
  color: Colors.primary,
  borderColor: Colors.primary,
};
