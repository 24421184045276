import React from "react";
import { useAccount, useBalance, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector, FetchBalanceResult } from "@wagmi/core";
// import { bsc } from "@wagmi/core/chains";
import { nautilusMainnet } from "./wagmi-context";
interface WalletContextType {
  accountAddress: `0x${string}` | undefined;
  isConnected: boolean;
  nativeBalance: FetchBalanceResult | undefined;
  connect: any;
  disconnect: any;
  isLoading:boolean
}

const WalletContext = React.createContext<WalletContextType>({
  accountAddress: undefined,
  isConnected: false,
  nativeBalance: undefined,
  isLoading:false,
  connect: () => {},
  disconnect: () => {},
});

export const useWallet = () => React.useContext(WalletContext);

const useProviderWallet = () => {
  const { address: accountAddress, isConnected } = useAccount();
  const { data: nativeBalance } = useBalance({
    address: accountAddress,
    chainId: nautilusMainnet.id,
    watch: true,
  });
  const { connect,isLoading } = useConnect({
    connector: new InjectedConnector({ chains: [nautilusMainnet] }),
    chainId: nautilusMainnet.id,
  });
  const { disconnect } = useDisconnect();
  return { accountAddress, isConnected, nativeBalance, connect, disconnect ,isLoading};
};

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useProviderWallet();
  return (
    <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
  );
};
export default WalletProvider;
