import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = "HIPQa4N7mzyvl7GLtbDrJg";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: [
      "en",
      "ms", // Malay
      "ko",
      "ru", // Russian
      "es", // Spanish
      "hi", // Hindi
      "vi",
      "ja",
      "zh",
      "ar", // Arabic
      "id", // Indonesian
    ],

    backend: {
      loadPath: loadPath,
    },
    react: {
      // nodes allowed to be kept
      transKeepBasicHtmlNodesFor: [
        "br",
        "strong",
        "i",
        "p",
        "span",
        "ul",
        "li",
        "NavLinkWithQuery",
        "h1",
      ],
    },
  });
