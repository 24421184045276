import React, { useContext } from "react";
import { ethers } from "ethers";
import { useContractRead } from "wagmi";
import abi from "../abi/NameServiceInterface";

export enum NameserviceKeys {
  InvitationCenter = "InvitationCenter",
  ZbcPool = "ZbcPool",
}

type NameserviceContextType = Record<string, string> | null;

const NameserviceContext = React.createContext<NameserviceContextType>(null);

export const useNameservice = () => useContext(NameserviceContext);

const decodeEntries = (records: Array<Record<string, any>> | undefined) =>
  records != null
    ? records.reduce(
        (accum, item) => ({
          ...accum,
          [ethers.utils.parseBytes32String(item.name)]: item.record.addr,
        }),
        {}
      )
    : null;

const useProvideNameservice = () => {
  
  // @ts-ignore
  let ver = window._zbc_app_ver
  
  let REACT_APP_NS = ""
  if(ver === "u"){
    REACT_APP_NS = "0x2f05983E5211ceF90463073181c6caeC147EFc4c"
  }else{
    // ver z
    REACT_APP_NS = "0x2f05983E5211ceF90463073181c6caeC147EFc4c"
  }

  const { data: singleEntries } = useContractRead({
    address: ethers.utils.getAddress(REACT_APP_NS!),
    abi,
    functionName: "listSingleEntries",
  });
  let decodedSingleEntries = decodeEntries(singleEntries as any);
  
  if(ver === "z"){
    decodedSingleEntries = {
      ...decodedSingleEntries,
      ZbcPool:decodedSingleEntries?.ZbcDirectPool,
    }
  }

  return decodedSingleEntries;
};

const NameserviceProvider = ({ children }: { children: React.ReactNode }) => {
  const addresses = useProvideNameservice();
  return (
    <NameserviceContext.Provider value={addresses}>
      {children}
    </NameserviceContext.Provider>
  );
};

export default NameserviceProvider;
