import React, { useState } from "react";

import { Button, Modal, Alert } from "antd";
import { CloseOutlined } from "@ant-design/icons";
const LaunchButton = ({
  children,
  fixed,
  plan,
}: {
  children: any;
  fixed?: boolean;
  plan?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(fixed);

  // const this_site = "zbcstaking.com";
  // const this_site = window.location.hostname;

  let params = document.location.search;

  if (plan) {
    if (params.length === 0) params += "?";
    params += "&plan=" + plan;
  }

  const jump = (ver: string) => {
    // disconnect();
    if (ver === "U") {
      window.open("/zbc-u/staking" + params, "_self");
    } else {
      window.open("/zbc-z/staking" + params, "_self");
    }
  };

  return (
    <>
      <Modal
        title="Switch"
        centered
        footer={null}
        width={400}
        open={isModalOpen}
        onCancel={() => {
          if (!fixed) setIsModalOpen(false);
        }}
        closeIcon={fixed ? <></> : <CloseOutlined />}
      >
        <div style={{ margin: "40px 20px" }}>
          <Button
            onClick={() => jump("U")}
            style={{ marginBottom: "40px" }}
            size="large"
            block
            type="primary"
          >
            ZBC-U
          </Button>
          <Button onClick={() => jump("Z")} size="large" block type="primary">
            ZBC-Z
          </Button>
        </div>
        <Alert
          style={{ margin: "20px" }}
          showIcon
          message="Please note that the two staking features are completely independent (including but not limited to staking, invitation relationships, etc.)"
          type="info"
        />
      </Modal>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
    </>
  );
};

export default LaunchButton;
