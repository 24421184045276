import React from "react";
import LaunchButton from "../../components/LaunchButton";
const Switch: React.FC = () => {
  return (
    <>
      <LaunchButton fixed={true}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "#888",
          }}
        ></div>
      </LaunchButton>
    </>
  );
};

export default Switch;
