import React, { useCallback, useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { apyParma, zbcPriceUrl, metrics, direcitApyParma } from "../utils/urls";
import { useAccount } from "wagmi";
import { WifiOutlined } from "@ant-design/icons";
import { useInvitationCenter } from "./invitation-center-context";
import { useWallet } from "./wallet-context";
interface backendContextType {
  ZbcPriceRaw: any;
  ready: boolean;
  ZbcPriceFormated: any;
  apy: any;
  metricsData: any;
  error: any;
  fetchData: any;
}

const backendContext = React.createContext<backendContextType>({
  ZbcPriceRaw: undefined,
  ready: false,
  ZbcPriceFormated: 0,
  apy: undefined,
  metricsData: undefined,
  error: undefined,
  fetchData: undefined,
});

export const useBackend = () => React.useContext(backendContext);

const useProviderbackend = () => {
  const [ZbcPriceRaw, setZbcPriceRaw] = useState({});
  const [apy, setApy] = useState({});
  const [metricsData, setMetricsData] = useState({});
  const [ZbcPriceFormated, setZbcPriceFormated] = useState("0");
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const { address: accountAddress } = useAccount();
  const invitationCenter = useInvitationCenter();
  const wallet = useWallet();
  // @ts-ignore
  const ver = window._zbc_app_ver;

  const fetchData = useCallback(
    async function () {
      try {
        const { isRegistered } = invitationCenter;

        if (ver === "u") {
          // ver u
          const data = await (await fetch(zbcPriceUrl)).json();

          setZbcPriceRaw(data);
          setZbcPriceFormated(
            ethers.utils.formatEther(data.price.zbcPriceFixedPoint18)
          );
        }
        // ver z

        if (ver === "u") {
          // ver u
          if (!!accountAddress && !!isRegistered) {

            const apyData = await (
              await fetch(`${apyParma}?address=${accountAddress}`)
            ).json();
            setApy(apyData);
          }
        } else {
          // ver z
          if (!!accountAddress && !!isRegistered) {

            const apyData = await (
              await fetch(`${direcitApyParma}?address=${accountAddress}`)
            ).json();
            setApy(apyData);
          }
        }

        // metricsData is not important
        try {
          const metricsData = (await (await fetch(`${metrics}`)).json())
            ?.result;
          setMetricsData(metricsData);
        } catch (error) {
          console.log("warning! can't fetch metrics data ");
        }

        // Everything is done
        setReady(true);
        setError(false);
        // when fetch success,reset timer
      } catch (error) {
        setReady(false);
        setError(true);
        console.log("backend down!");
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountAddress, wallet.isConnected]
  );

  let timer: any = useRef(null);

  useEffect(() => {
    fetchData();
    if (!timer.current) {
      timer.current = setInterval(() => {
        fetchData();
      }, 1000 * 10);
    }
  }, [fetchData]);

  return {
    ZbcPriceRaw,
    ready,
    ZbcPriceFormated,
    apy,
    metricsData,
    error,
    fetchData,
  };
};

const BackendProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useProviderbackend();
  return (
    <backendContext.Provider value={data}>
      <div
        className="network-warning"
        style={{ display: data.error ? "block" : "none", color: "red" }}
      >
        <WifiOutlined style={{ paddingRight: "5px" }} />
      </div>
      {children}
    </backendContext.Provider>
  );
};
export default BackendProvider;
