import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import WalletProvider from "./store/wallet-context";
import WagmiProvider from "./store/wagmi-context";
import NameserviceProvider from "./store/nameservice-context";
import InvitationCenterProvider from "./store/invitation-center-context";
import ZbcPoolProvider from "./store/zbc-pool-context";
import BackendProvider from "./store/backend";
import "./i18n";
import { Spin } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="app-loading">
          <Spin size="large" />
        </div>
      }
    >
      <WagmiProvider>
        <NameserviceProvider>
          <WalletProvider>
            <InvitationCenterProvider>
              <BackendProvider>
                <ZbcPoolProvider>
                  <App />
                </ZbcPoolProvider>
              </BackendProvider>
            </InvitationCenterProvider>
          </WalletProvider>
        </NameserviceProvider>
      </WagmiProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
