import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";

// @ts-ignore
import en from "../../assets/images/language/en.png";
// @ts-ignore
import zh from "../../assets/images/language/zh.png";
// @ts-ignore
import ja from "../../assets/images/language/ja.png";
// @ts-ignore
import ko from "../../assets/images/language/ko.png";
// @ts-ignore
import es from "../../assets/images/language/es.png";
// @ts-ignore
import ru from "../../assets/images/language/ru.png";
// @ts-ignore
import hi from "../../assets/images/language/hi.png";
// @ts-ignore
import ar from "../../assets/images/language/ar.png";
// @ts-ignore
import ms from "../../assets/images/language/ms.png";
// @ts-ignore
import vi from "../../assets/images/language/vi.png";
// @ts-ignore
import id from "../../assets/images/language/id.png";

const langs = [
  { key: "en", label: "English", icon: en },
  { key: "zh", label: "简体中文", icon: zh },
  { key: "ja", label: "日本語", icon: ja },
  { key: "ko", label: "한국의", icon: ko },
  { key: "es", label: "Español", icon: es },
  { key: "ru", label: "Русский", icon: ru },
  { key: "hi", label: "हिन्दी", icon: hi },
  { key: "ar", label: "عربي", icon: ar },
  { key: "ms", label: "Orang Melayu", icon: ms },
  { key: "vi", label: "tiếng Việt", icon: vi },
  { key: "id", label: "Bahasa Indonesia", icon: id },
];

const LangButton = () => {
  // @ts-ignore
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState("en");

  useEffect(() => {
    // const lang: string = i18n.resolvedLanguage || "en";

    // setCurrentLang(lang);
    // always switch to english when start up
    // TODO: recovery lang switch function
    
    setCurrentLang("en");
    i18n.changeLanguage("en");
  }, [i18n, i18n.resolvedLanguage, setCurrentLang]);

  const items: MenuProps["items"] = langs.map((e) => ({
    key: e.key,
    label: e.label,
  }));

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setCurrentLang(key);
    i18n.changeLanguage(key);
  };

  return (
    <div style={{display:"none"}}>
      <Dropdown
        trigger={["click"]}
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [currentLang],
          onClick,
        }}
      >
        <div onClick={(e) => e.preventDefault()}>
          <Space>
            <img
              style={{ width: "20px", verticalAlign: "middle" }}
              src={langs.find((item) => item.key === currentLang)?.icon}
              alt=""
            />
            <span className="lang-text">
              {langs.find((item) => item.key === currentLang)?.label}
            </span>
            <DownOutlined />
          </Space>
        </div>
      </Dropdown>
    </div>
  );
};

export default LangButton;
