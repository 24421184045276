import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Alert,
  Pagination,
  Tooltip,
  Progress,
} from "antd";
import { HeaderStaking } from "../../components";
import SubmitStake from "../../components/SubmitStack";
import FlexTable from "../../components/FlexTable";
import "./index.scss";
import { CustomerFooter } from "../../components";
import { BigNumber, ethers } from "ethers";
import dayjs from "dayjs";

import {
  NameserviceKeys,
  useNameservice,
} from "../../store/nameservice-context";
import { useWallet } from "../../store/wallet-context";
import { useZbcPool } from "../../store/zbc-pool-context";
import { useContract, useProvider, useSigner } from "wagmi";
import relativeTime from "dayjs/plugin/relativeTime";
import { useBackend } from "../../store/backend";
import abi from "../../abi/ZBCPoolInterface";
import { formatAmount } from "../../utils/utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import "dayjs/locale/en";
import "dayjs/locale/ms";
import "dayjs/locale/ko";
import "dayjs/locale/ru";
import "dayjs/locale/es";
import "dayjs/locale/hi";
import "dayjs/locale/vi";
import "dayjs/locale/ja";
import "dayjs/locale/ar";
import "dayjs/locale/id";
import "dayjs/locale/zh-cn";
import i18next, { t } from "i18next";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { useLocation } from "react-router-dom";
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const Staking: React.FC = () => {
  const { pathname } = useLocation();
  const [ant_modal, contextHolder] = Modal.useModal();
  const wallet = useWallet();
  const { data: singer } = useSigner();
  const provider = useProvider();
  let [symbol, setSymbol] = useState("");

  // @ts-ignore
  const ver = window._zbc_app_ver;

  // scroll to top when path change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (provider?.chains) {
      setSymbol(provider?.chains[0]?.nativeCurrency.symbol || "");
    }
  }, [provider.chains]);

  if (provider.chains) {
    symbol = provider?.chains[0]?.nativeCurrency.symbol;
  }
  const zbcPool = useZbcPool();
  const backend = useBackend();
  const ns = useNameservice();
  const [isModalOpen, showModal] = useState(false);
  let [billInfo, setBillInfo] = useState<billInfoInterface>({
    ready: false,
    stakedZBC: "",
    zbcAmount: "",
    zbcAmountNumber: 0,
    efficientUsd: 0,
    dailyRate: "",
    plan: "",
    apy: "",
    actions: <></>,
    endsIn: <></>,
    EstimatedEarn: "",
    earned: "",
    id: "",
    timeout: false,
    withdrawTime: "",
    estimatedEarnZBC: 0,
    burnDay: 0,
    NOT_EXPIRED: true,
    endsTimeForSort: 0,
    lockTimeText: "--",
    lockTime: 0,
  });
  const [earlyWithdrawalZbc, setEarlyWithdrawalZbc] = useState(<></>);
  const [readyToWithdrawl, setReadyToWithdrawl] = useState(false);
  const [efficientYield, setEfficientYield] = useState(<></>);
  const zbc = useContract({
    address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.AddressZero,
    abi,
    signerOrProvider: singer,
  });
  let DAY_SCALE_BN = BigNumber.from(1);

  useEffect(() => {
    // console.log(i18next.language);
    if (i18next.language === "zh") {
      dayjs.locale("zh-cn");
    } else {
      dayjs.locale(i18next.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  try {
    DAY_SCALE_BN = ethers.BigNumber.from(60 * 60 * 24).div(
      zbcPool?.globalConfig?.oneDay
    );
  } catch (error) {
    // can't get oneday config
    console.warn("can't get oneday config,set oneDay to one day");
  }

  const DAY_SCALE = DAY_SCALE_BN.toNumber();

  interface billInfoInterface {
    stakedZBC: string;
    zbcAmount: string;
    zbcAmountNumber: number;
    dailyRate: string;
    plan: string;
    actions: ReactElement;
    apy: string;
    endsIn: ReactElement | String;
    EstimatedEarn: string;
    earned: string;
    id: string;
    ready: boolean;
    timeout: Boolean;
    withdrawTime: string;
    estimatedEarnZBC: number;
    efficientUsd: number;
    burnDay: number;
    NOT_EXPIRED: boolean;
    endsTimeForSort: number;
    lockTimeText: string;
    lockTime: number;
  }

  const billDataFormater = (
    data: any,
    i: number,
    bills: any,
    history?: boolean
  ) => {
    let result: billInfoInterface = {
      ready: false,
      stakedZBC: "",
      zbcAmount: "",
      zbcAmountNumber: 0,
      efficientUsd: 0,
      dailyRate: "",
      plan: "",
      apy: "",
      actions: <></>,
      endsIn: <></>,
      EstimatedEarn: "",
      earned: "",
      id: "",
      timeout: false,
      withdrawTime: "",
      estimatedEarnZBC: 0,
      burnDay: 0,
      NOT_EXPIRED: true,
      endsTimeForSort: 0,
      lockTimeText: "--",
      lockTime: 0,
    };
    try {
      if (data && zbcPool) {
        // [1]: zbcAmount
        const zbcAmountBN = data?.zbcAmount;
        const USDAmountBN = data?.efficientUsd;
        let zbcAmount = "--";
        let USDAmount = "--";
        result.efficientUsd = 0;
        if(bills.length === 0){
          return result;
        }
        
        result.id = bills[i].toString();
        try {
          zbcAmount = formatAmount(zbcAmountBN);
          USDAmount = formatAmount(USDAmountBN);
          result.efficientUsd = parseFloat(USDAmount);
        } catch (error) {
          console.log(error);
        }
        if (ver === "u") {
          result.stakedZBC = `${formatAmount(
            zbcAmount
          )}${symbol}(=${formatAmount(USDAmount)}$)`;
        } else {
          result.stakedZBC = `${formatAmount(zbcAmount)}${symbol}`;
        }

        result.zbcAmount = zbcAmount;
        result.zbcAmountNumber = parseFloat(zbcAmount);
        // [2]: efficientUsd
        const currentZBCPrice = backend.ZbcPriceFormated;

        // [3]: lockTimestamp
        const lockTime = dayjs(data?.lockTimestamp?.toNumber() * 1000);
        result.lockTimeText = lockTime.utc().format();
        result.lockTime = lockTime.valueOf();
        // [5]: finishedTimestamp
        const finishedTime = dayjs(data?.finishedTimestamp?.toNumber() * 1000);
        const APY_IN_PERCENT = data?.apyInMillion.toNumber() / 10000;

        const currentTime = dayjs();
        const currentTimeScaled = dayjs(
          lockTime.valueOf() +
            (currentTime.valueOf() - lockTime.valueOf()) * DAY_SCALE
        );
        // const APY_IN_PERCENT = 0

        const DURATION_OF_YEAR =
          data.finishedTimestamp
            .sub(data.lockTimestamp)
            .mul(DAY_SCALE_BN)
            .toNumber() /
          (60 * 60 * 24 * 365);

        const DAILY_RATE_IN_PERCENT = APY_IN_PERCENT / 365;

        result.dailyRate =
          formatAmount(DAILY_RATE_IN_PERCENT, { precision: 6 }) + " %";

        // [7] : apyInMillion
        result.apy = APY_IN_PERCENT + " %";

        const planEndsInDayScaled = dayjs(
          lockTime.valueOf() +
            (finishedTime.valueOf() - lockTime.valueOf()) * DAY_SCALE
        );

        result.plan = `${Math.floor(
          dayjs
            .duration(planEndsInDayScaled.valueOf() - lockTime.valueOf())
            .asDays()
        )} Days`;

        result.endsTimeForSort =
          currentTimeScaled.valueOf() - planEndsInDayScaled.valueOf();

        // TIMEOUT = Plan ends already
        const TIMEOUT: boolean =
          currentTimeScaled.valueOf() - planEndsInDayScaled.valueOf() >
          1000 * 60 * 60 * 24;
        const NOT_EXPIRED: boolean =
          planEndsInDayScaled.valueOf() - currentTimeScaled.valueOf() > 0;
        result.NOT_EXPIRED = NOT_EXPIRED;
        result.actions = (
          <Button
            onClick={() => {
              setBillInfo(result);
              // console.log("start stake bill ",result);
              // console.log("all bills ",zbcPool.getActiveBill);
              withdrawStake(result, NOT_EXPIRED, NOT_EXPIRED);
            }}
            danger={NOT_EXPIRED ? true : false}
            type={NOT_EXPIRED ? "default" : "primary"}
            loading={!backend.ready}
          >
            {t("Withdraw")}
          </Button>
        );
        result.burnDay = 0;
        if (TIMEOUT) {
          const endsTime = dayjs.duration(
            currentTimeScaled.valueOf() - planEndsInDayScaled.valueOf()
          );
          const endsTimeString = `${Math.floor(
            endsTime.asDays()
          )} D  ${Math.floor(endsTime.hours())} H
          ${Math.floor(endsTime.minutes())} M ago`;
          // timeout,burn start!
          result.burnDay = endsTime.asDays();

          result.endsIn = (
            <span style={{ color: "white" }}>{endsTimeString}</span>
          );
        } else {
          const endsTime = dayjs.duration(
            planEndsInDayScaled.valueOf() - currentTimeScaled.valueOf()
          );
          const endsTimeString = `In ${Math.floor(
            endsTime.asDays()
          )} D ${Math.floor(endsTime.hours())} H ${Math.floor(
            endsTime.minutes()
          )} M`;
          result.endsIn = <span>{endsTimeString}</span>;
          if (currentTimeScaled.valueOf() - planEndsInDayScaled.valueOf() > 0) {
            const endsTime = dayjs.duration(
              currentTimeScaled.valueOf() - planEndsInDayScaled.valueOf()
            );
            const endsTimeString = `${Math.floor(
              endsTime.asDays()
            )} D  ${Math.floor(endsTime.hours())} H
            ${Math.floor(endsTime.minutes())} M`;
            result.endsIn = <span>{endsTimeString}</span>;
          }
        }
        const EstimatedEarnZBC =
          (DURATION_OF_YEAR * APY_IN_PERCENT * parseFloat(zbcAmount)) / 100;
        const EstimatedEarnUSD = EstimatedEarnZBC * currentZBCPrice;
        result.estimatedEarnZBC = EstimatedEarnZBC;
        result.EstimatedEarn = `${formatAmount(
          EstimatedEarnZBC
        )}${symbol}(≈${formatAmount(EstimatedEarnUSD)}$)`;
        result.timeout = TIMEOUT;
        if (history) {
          let earnedZBCText = formatAmount(data.cumulativeZbcTaken);
          let earnedUSDText = formatAmount(data.cumulativeUsdTaken);
          if (ver === "u") {
            // ver u
            result.earned = `${earnedZBCText}${symbol}(≈${earnedUSDText}$)`;
          } else {
            // ver z
            result.earned = `${earnedZBCText}${symbol}`;
          }
          result.withdrawTime = finishedTime.format("MM/DD/YYYY HH:mm:ss");
        }

        // No error catched.so this bill is ready to display
        result.ready = true;
      }
    } catch (error) {
      console.log("cant't format bill data: ", error);
    }
    return result;
  };

  let activeBillDataFormated: Array<any> = [];
  if (zbcPool.allActiveBills) {
    try {
      let _activeBillDataFormated = zbcPool.allActiveBills.map(
        (e: any, i: number) =>
          billDataFormater(e, i, zbcPool.getActiveBill, false)
      );
      activeBillDataFormated = _activeBillDataFormated.sort(
        (a: billInfoInterface, b: billInfoInterface) => {
          return b.endsTimeForSort - a.endsTimeForSort;
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  let withdrawnBillDataFormated: Array<any> = [];
  if (zbcPool.allWithdrawnBills) {
    try {
      let _withdrawnBillDataFormated = zbcPool.allWithdrawnBills.map(
        (e: any, i: number) =>
          billDataFormater(e, i, zbcPool.getWithdrawnBill, true)
      );
      withdrawnBillDataFormated = _withdrawnBillDataFormated.sort(
        (a: billInfoInterface, b: billInfoInterface) => {
          return b.lockTime - a.lockTime;
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const realWithdraw = async (billNumbers: Array<any>, early: boolean) => {
    // 暂时不允许提现
    // return false

    const { signature, price } = backend.ZbcPriceRaw;

    const doWithdraw = () => {
      if (ver === "u") {
        // ver u
        if (zbcPool.withdraw) {
          zbcPool
            .withdraw({
              recklesslySetUnpreparedArgs: [price, signature, billNumbers],
            })
            .catch(() => {});
        }
      } else {
        // ver z
        if (zbcPool.withdraw) {
          zbcPool
            .withdraw({
              recklesslySetUnpreparedArgs: [billNumbers],
            })
            .catch(() => {});
        }
      }
    };
    // if (early) {
    //   // ant_modal.confirm({
    //   //   title: "Are you sure to withdraw with penalties ?",
    //   //   onOk: () => doWithdraw(),
    //   //   centered: true,
    //   // });
    // } else {
      doWithdraw();
    // }

    console.log("real withDraw bill:", billNumbers[0].toNumber());
  };

  const withdrawStake = async (
    info: billInfoInterface,
    alarm: Boolean,
    early: boolean
  ) => {
    try {
      if (alarm && zbcPool.withdraw) {
        setReadyToWithdrawl(false);
        showModal(true);
        setEarlyWithdrawalZbc(
          <>
            <Spin></Spin> <span>{t("Calculating...")}</span>
          </>
        );
        setEfficientYield(
          <>
            <Spin></Spin> <span>{t("Calculating...")}</span>
          </>
        );
        let data: any = null;
        // if (ver === "u") {
        //   // ver u
        //   const { signature, price } = backend.ZbcPriceRaw;
        //   data = await zbc?.callStatic?.withdraw(
        //     price,
        //     signature,
        //     [BigNumber.from(info.id)],
        //     { from: wallet.accountAddress }
        //   );
        // } else {
        //   // ver z
        //   data = await zbc?.callStatic?.withdraw([BigNumber.from(info.id)], {
        //     from: wallet.accountAddress,
        //   });
        // }
        // const withdrawZbcAmount = parseFloat(
        //   ethers.utils.formatEther(ethers.BigNumber.from(data))
        // );

        // setEarlyWithdrawalZbc(
        //   <span
        //     style={{
        //       color: "red",
        //       fontWeight: "bold",
        //       fontSize: "1rem",
        //       lineHeight: "30px",
        //     }}
        //   >
        //     {formatAmount(withdrawZbcAmount)}
        //     {symbol}
        //     {ver === "u"
        //       ? "(≈" +
        //         formatAmount(withdrawZbcAmount * backend.ZbcPriceFormated) +
        //         "$)"
        //       : ""}
        //   </span>
        // );

        // const efficientZBC = withdrawZbcAmount - info.zbcAmountNumber;

        // setEfficientYield(
        //   <span
        //     style={{
        //       color: efficientZBC < 0 ? "red" : "green",
        //       fontSize: "1rem",
        //       lineHeight: "30px",
        //     }}
        //   >
        //     {formatAmount(efficientZBC)}
        //     {symbol}
        //     {ver === "u"
        //       ? "(≈" +
        //         formatAmount(
        //           efficientZBC * parseFloat(backend.ZbcPriceFormated)
        //         ) +
        //         "$)"
        //       : ""}
        //   </span>
        // );

        if (zbcPool.withdraw) {
          setReadyToWithdrawl(true);
        }
      } else {
        // console.log("real withdraw bill ", info);

        realWithdraw([BigNumber.from(info.id)], false);
      }
    } catch (error) {
      console.log(error);

      showModal(false);
      zbcPool.modal.error({
        title: "Transaction unsuccessful",
        content: "Network error",
        centered: true,
      });
    }
  };

  const computePunishment = useCallback((billInfo: billInfoInterface) => {
    let result = "$ 0";
    // const burnDay = billInfo.burnDay;
    // try {
    //   const usdPunishment = (billInfo.efficientUsd * burnDay * 180) / 10000;
    //   result =
    //     "$ " + formatAmount(Math.min(usdPunishment, billInfo.efficientUsd));
    // } catch (error) {
    //   console.log(error);
    // }
    return result;
  }, []);

  const modal = (
    <Modal
      centered
      open={isModalOpen}
      title={t("Withdraw")}
      closeIcon={<></>}
      maskClosable={false}
      confirmLoading={!readyToWithdrawl || !backend.ready}
      onCancel={() => showModal(false)}
      keyboard={false}
      okText={t("Ok")}
      cancelText={t("Cancel")}
      onOk={() => {
        showModal(false);
        // console.log("real withdraw bill:", billInfo);
        realWithdraw([BigNumber.from(billInfo.id || 0)], billInfo.NOT_EXPIRED);
      }}
    >
      {false ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <span>Plan ends</span>
            <span>{billInfo.endsIn}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{t("Staked")}</span>
            <span>{billInfo.stakedZBC}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              {t("Bonus") + " "}
              <Tooltip title={"Bonus Tip"}>
                <QuestionCircleOutlined
                  size={28}
                  style={{ color: "#B3B3B3", paddingLeft: "5px" }}
                />
              </Tooltip>
            </span>

            <span>
              {zbcPool.calcAdditionRewardRateInMillion
                ? formatAmount(
                    zbcPool.calcAdditionRewardRateInMillion
                      .sub(1000000)
                      .toNumber() / 10000
                  ) + " %"
                : "--"}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ lineHeight: "30px" }}>{"Efficient yield"}</span>
            <span style={{ lineHeight: "30px" }}>{efficientYield}</span>
          </div>
          <br />
          {/* <Row>
            <Col className="p4m" span={24}>
              <Alert
                message={
                  <>
                    <Row
                      style={{
                        display: billInfo.timeout ? "none" : "block",
                        padding: 0,
                      }}
                    >
                      EARLY WITHDRAWAL Penalty = 88% of the projected rewards,
                      which is{" "}
                      <span style={{ color: "red" }}>
                        {formatAmount(billInfo.estimatedEarnZBC * 0.88)} ZBC.
                      </span>
                      <br />
                      If the income is not enough, the principal is deducted
                    </Row>
                    <Row
                      style={{ display: billInfo.timeout ? "block" : "none" }}
                    >
                      <Trans
                        i18nKey="late_withdrawal_warn"
                        values={{
                          amount: computePunishment(billInfo),
                        }}
                        components={{
                          amount: (
                            <span
                              style={{ color: "red", fontWeight: "bold" }}
                            ></span>
                          ),
                        }}
                      ></Trans>
                    </Row>
                  </>
                }
                type="warning"
              />
            </Col>
          </Row> */}
          <br />
          <Row justify="space-between">
            <Col style={{ lineHeight: "30px" }}>Withdrawal amount</Col>
            <Col style={{ lineHeight: "30px" }}>{earlyWithdrawalZbc}</Col>
          </Row>
          <br />
        </>
      )}
    </Modal>
  );

  interface columnInterface {
    title: string;
    key: string;
    tip?: string;
    span?: number;
  }

  const columns: Array<columnInterface> = [
    {
      key: "plan",
      title: t("Plan"),
      span: 2,
    },
    {
      key: "apy",
      title: t("APY"),
      span: 2,
    },
    {
      key: "dailyRate",
      title: "Daily Rate",
      span: 3,
    },
    {
      key: "stakedZBC",
      title: `Staked in ${symbol}`,
      span: 5,
    },
    {
      key: "EstimatedEarn",
      title: "Estimated Earn",
      span: 5,
    },
    {
      key: "endsIn",
      title: "End",
      span: 5,
    },
    {
      key: "actions",
      title: "Withdraw",
      span: 2,
    },
  ];

  const sh_columns: Array<columnInterface> = [
    {
      key: "plan",
      title: t("Plan"),
      span: 3,
    },
    {
      key: "apy",
      title: t("APY"),
      span: 3,
    },
    {
      key: "dailyRate",
      title: "Daily Rate",
      span: 4,
    },
    {
      key: "stakedZBC",
      title: `Staked in ${symbol}`,
      span: 5,
    },
    {
      key: "earned",
      title: "Withdrawn Amount",
      span: 5,
    },
    {
      key: "lockTimeText",
      title: "Lock Time",
      span: 4,
    },
    // {
    //   key: "withdrawTime",
    //   title: t("withdraw_time"),
    //   span: 5,
    // },
  ];

  return (
    <div className="stake-page">
      <HeaderStaking />
      <div>{contextHolder}</div>
      <div className="container">
        <div style={{ padding: "30px 0" }}></div>
        <Row
          gutter={40}
          justify="space-between"
          style={{ padding: "30px 0 40px 0" }}
        >
          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <SubmitStake />
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <StakeBonuses />
          </Col>
        </Row>
        {/* use components rather than fragments! */}
        <Row style={{ position: "relative" }} justify="center">
          <Col span={24} className="card-bt40">
            <Card bordered={false} title={"Active Stakes"}>
              {wallet.accountAddress ? (
                <>
                  {zbcPool.activeBillsLoadingPercent === 100 ? (
                    <>
                      <FlexTable
                        meta={zbcPool?.getActiveBill
                          ?.map((e: BigNumber) => e.toString())
                          .slice(
                            (zbcPool.activeBillPage - 1) * 10,
                            zbcPool.activeBillPage * 10
                          )}
                        id="activeBill"
                        hasTitle
                        dataSource={activeBillDataFormated.slice(
                          (zbcPool.activeBillPage - 1) * 10,
                          zbcPool.activeBillPage * 10
                        )}
                        columns={columns}
                      />
                      <Pagination
                        total={zbcPool?.getActiveBill?.length || 0}
                        onChange={(index) => {
                          zbcPool.setActiveBillPage(index);
                        }}
                        showSizeChanger={false}
                        current={zbcPool.activeBillPage}
                      />
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Progress
                        strokeColor="#59D89D"
                        type="circle"
                        percent={zbcPool.activeBillsLoadingPercent}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Alert message={"No Wallet Connection"} type="success" />
                </>
              )}
            </Card>
          </Col>
          <Col span={24} className="card-bt40">
            <Card bordered={false} title={"Stake History"}>
              {wallet.accountAddress ? (
                <>
                  {zbcPool.withdrawnBillsLoadingPercent === 100 ? (
                    <>
                      <FlexTable
                        meta={zbcPool?.getWithdrawnBill
                          ?.map((e: BigNumber) => e.toString())
                          .slice(
                            (zbcPool.withdrawnBillPage - 1) * 10,
                            zbcPool.withdrawnBillPage * 10
                          )}
                        id="withdrawnBill"
                        hasTitle
                        dataSource={withdrawnBillDataFormated.slice(
                          (zbcPool.withdrawnBillPage - 1) * 10,
                          zbcPool.withdrawnBillPage * 10
                        )}
                        columns={sh_columns}
                      />
                      <Pagination
                        total={zbcPool?.getWithdrawnBill?.length || 0}
                        onChange={(index) => {
                          zbcPool.setWithdrawnBillPage(index);
                        }}
                        showSizeChanger={false}
                        current={zbcPool.withdrawnBillPage}
                      />
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Progress
                        strokeColor="#59D89D"
                        type="circle"
                        percent={zbcPool.withdrawnBillsLoadingPercent}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Alert message="No Wallet Connection" type="success" />
                </>
              )}
            </Card>
          </Col>
          <div
            style={{ display: zbcPool?.withdrawIsLoading ? "block" : "none" }}
            className="trade-spin"
          >
            <Spin size="large" />
          </div>
        </Row>
      </div>
      <CustomerFooter />
      {modal}
    </div>
  );
};

export default Staking;

const StakeBonuses: React.FC = function () {
  const zbcPool = useZbcPool();
  const backend = useBackend();
  const wallet = useWallet();
  const provider = useProvider();
  let symbol = wallet.nativeBalance?.symbol || "";

  if (provider.chains) {
    symbol = provider?.chains[0]?.nativeCurrency.symbol;
  }

  // @ts-ignore
  const ver = window._zbc_app_ver;

  return (
    <Card style={{ height: "100%" }} bordered={false}>
      <Row
        style={{ marginLeft: "-10px", marginRight: "-10px" }}
        justify="space-between"
        gutter={[0, 20]}
      >
        <Col span={12}>
          <div className="bordered-grid">
            <div>Active Users</div>
            <div>
              <span>
                {formatAmount(zbcPool?.getActivePersonLength, {
                  int: true,
                  number: true,
                })}
              </span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="bordered-grid">
            <div>Total Volume</div>
            <div>
              <span>
                {formatAmount(zbcPool?.globalConfig?.cumulativeStakedZbc)}{" "}
                {symbol}
              </span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="bordered-grid">
            <div>Total Transactions</div>
            <div>
              <span>{backend?.metricsData?.metrics?.TRANSACTION || "--"}</span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          {ver === "u" ? (
            <div className="bordered-grid">
              <div>Total Staked</div>
              <div>
                <span>
                  ${" "}
                  {formatAmount(
                    zbcPool?.globalConfig?.cumulativeStakedEfficientUsd
                  )}
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {/* <Card.Meta style={{ paddingTop: "20px" }} title={<>Stake Bonuses</>} />
      <Row className="as-tb" justify="space-between">
        <Col>Bigger pays better</Col>
        <Col>
          +0.00 <span>{symbol}</span>
        </Col>
      </Row>
      <Row className="as-tb" justify="space-between">
        <Col>Bigger pays better</Col>
        <Col>
          +0.00 <span>{symbol}</span>
        </Col>
      </Row> */}
    </Card>
  );
};
