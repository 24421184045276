import React from "react";
import "./App.css";
import "./app.scss";
// import LandingPage from './views/LandingPage';
import { ConfigProvider, theme } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Claim from "./views/Claim";
import Staking from "./views/Staking";
import Refer from "./views/Refer";
import Index from "./views/Index";
import Switch from "./views/switch";
function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "#59D89D",
          },
          components: {
            Button: {
              colorPrimary: "#59D89D",
              borderRadiusLG: 100,
              borderRadius: 8,
              controlHeightLG: 50,
              colorTextLightSolid: "#000",
              colorLink: "#fff",
            },
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<LandingPage />} /> */}
            <Route path="/" element={<Index />} />
            <Route path="/zbc-u/staking/" element={<Staking />} />
            <Route path="/zbc-u/claim" element={<Claim />} />
            <Route path="/zbc-u/refer" element={<Refer />} />
            <Route path="/zbc-z/staking/" element={<Staking />} />
            <Route path="/zbc-z/claim" element={<Claim />} />
            <Route path="/zbc-z/refer" element={<Refer />} />
            <Route path="/staking" element={<Switch />} />
            <Route path="/refer" element={<Switch />} />
            <Route path="/claim" element={<Switch />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
