import { Layout, Button, Row, Col } from "antd";
import "./index.scss";
import logo from "../../assets/images/logo-full.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export const CustomerFooter = () => {
  const { Footer } = Layout;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Footer className="my-footer">
      <div className="container">
        <Row style={{ paddingBottom: "40px", display: "none" }}>
          <Col
            style={{ paddingTop: "20px" }}
            xs={24}
            md={24}
            sm={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            <img alt="" className="logo" src={logo} />
          </Col>
          <Col
            className="faq"
            xs={24}
            md={24}
            sm={24}
            lg={12}
            xl={12}
            xxl={12}
            style={{ textAlign: "right" }}
          >
            <span> {t("how_it_works")}</span>
            <span>{t("FAQ")}</span>
            <span>{t("More")}</span>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "40px" }}>
          <Col xs={24} md={24} sm={12} lg={12} xl={12} xxl={12} className="tou">
            <img alt="" className="logo" src={logo} />
            <div className="copyright">
              <span>{t("Copyright")}</span>
            </div>
            {/* <span>{t("terms_of_users")}</span> */}
            {/* <span style={{ color: "#8A8A8A", padding: "0 20px" }}>|</span> */}
            {/* <span>{t("privacy_policy")}</span> */}
            {/* <div className="copyright"> */}
            {/* <span>{t("Copyright")}</span> */}
            {/* </div> */}
          </Col>
          <Col
            className="action"
            xs={24}
            md={24}
            sm={24}
            lg={12}
            xl={12}
            xxl={12}
            style={{
              display: "flex",
              justifyContent: "right",
              flexWrap: "wrap",
            }}
          >
            {/* <img
              width="40px"
              height="40px"
              style={{ marginRight: "60px" }}
              alt=""
              onClick={()=>window.open("https://t.me/zebecprotocol",)}
            /> */}
            {/* <img
              width="40px"
              height="40px"
              style={{ marginRight: "60px" }}
              alt=""
              onClick={()=>window.open("https://twitter.com/Zebec_HQ",)}
            /> */}

            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              onClick={() => window.open("https://t.me/zebecprotocol")}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.39505 19.3188C13.7309 14.8156 20.623 11.8469 24.0715 10.4125C33.9177 6.31718 35.9637 5.60577 37.2972 5.58228C37.5905 5.57712 38.2463 5.6498 38.6711 5.99449C39.0298 6.28554 39.1285 6.6787 39.1757 6.95465C39.2229 7.2306 39.2817 7.85921 39.235 8.35039C38.7014 13.9566 36.3926 27.5615 35.2181 33.8406C34.7211 36.4975 33.7425 37.3883 32.7951 37.4755C30.7362 37.665 29.1727 36.1149 27.1785 34.8076C24.058 32.7621 22.2952 31.4888 19.2662 29.4927C15.7657 27.186 18.0349 25.9181 20.0298 23.8461C20.5519 23.3039 29.6236 15.0525 29.7992 14.3039C29.8211 14.2103 29.8415 13.8614 29.6342 13.6771C29.4269 13.4928 29.1209 13.5558 28.9001 13.606C28.5872 13.677 23.6021 16.9719 13.9451 23.4907C12.5301 24.4623 11.2485 24.9357 10.1001 24.9109C8.83422 24.8836 6.3991 24.1952 4.58883 23.6067C2.36847 22.885 0.60377 22.5034 0.757436 21.2776C0.837474 20.6392 1.71668 19.9862 3.39505 19.3188Z"
              />
            </svg>
            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              onClick={() => window.open("https://twitter.com/Zebec_HQ")}
            >
              <path d="M35.095 12.4645C35.1105 12.8165 35.1105 13.1525 35.1105 13.5045C35.1259 24.1765 27.2851 36.4966 12.9464 36.4966C8.7173 36.4966 4.56539 35.2326 1 32.8646C1.61738 32.9446 2.23477 32.9766 2.85215 32.9766C6.35581 32.9766 9.76686 31.7606 12.5297 29.5046C9.19578 29.4406 6.2632 27.1846 5.24452 23.8885C6.41755 24.1285 7.62145 24.0805 8.76361 23.7445C5.13647 23.0085 2.52803 19.6965 2.51259 15.8405C2.51259 15.8085 2.51259 15.7765 2.51259 15.7445C3.59301 16.3685 4.81235 16.7205 6.04712 16.7525C2.63607 14.3845 1.57108 9.6645 3.63932 5.96848C7.60601 11.0245 13.4403 14.0805 19.7067 14.4165C19.0739 11.6165 19.9383 8.67249 21.9602 6.68849C25.0934 3.63248 30.0325 3.79248 32.9959 7.04049C34.7401 6.68849 36.4224 6.01648 37.9504 5.07248C37.3639 6.94449 36.1446 8.52849 34.524 9.5365C36.0674 9.3445 37.58 8.91249 39 8.27249C37.9504 9.9045 36.6231 11.3125 35.095 12.4645Z" />
            </svg>

            <Button
              style={{ width: "215px" }}
              size="large"
              type="primary"
              onClick={() => navigate("/staking")}
            >
              Get Started
            </Button>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};
