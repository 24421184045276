import { Row, Col, Tooltip, Empty } from "antd";
import "./index.scss";
import { QuestionCircleOutlined, SyncOutlined } from "@ant-design/icons";
import React from "react";
// arrow 可以计算盈亏的情况（显示一个上或者下的箭头）
// rate 可以计算收益率，如 +80、-50%
// hidevalue 可以不显示原始值（一般是白色
// coloredValue 可以给数值染色（如正数绿色负数红色

interface columnConfig {
  title: string | React.ReactNode; // 显示的名字
  key: string; // 在数据表中的键名
  span?: number; // 这行数据占用的栅格宽度
  onRefresh?: Function | undefined; // 显示一个刷新图标，点击执行回调函数
  tip?: string; // 提示的文本,
  isLoading?: boolean;
}

interface dataInterface {
  columns: Array<columnConfig>;
  dataSource: Record<string, string | string[] | React.ReactNode>[];
  hasTitle?: boolean;
  id?: string | number;
  meta?: Array<any> | undefined;
}

const FlexTable: React.FC<dataInterface> = function ({
  columns,
  dataSource,
  hasTitle,
  id,
  meta,
}) {
  const defaultSpan = Math.floor(24 / columns.length);
  const addTip = (text: string) => (
    <Tooltip title={text}>
      <QuestionCircleOutlined size={28} style={{ color: "#B3B3B3" }} />
    </Tooltip>
  );

  return (
    <>
      {" "}
      {dataSource.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Row className="hide-for-m">
            {columns.map((e) => {
              const { span, tip, title } = e;
              return (
                <Col
                  key={`${id}-${title}`}
                  className="resp-tt"
                  xs={24}
                  md={24}
                  lg={span || defaultSpan}
                  xl={span || defaultSpan}
                  xxl={span || defaultSpan}
                >
                  <span>
                    <i></i>
                    {title} {tip ? addTip(tip) : ""}
                  </span>
                </Col>
              );
            })}
          </Row>
          {dataSource ? (
            dataSource.map((e, i) => {
              const this_meta = meta?.[i] ||e?.key|| "nometa";

              let result = [];
              if (e.ready) {
                for (let index = 0; index < columns.length; index++) {
                  const key = columns[index].key;
                  const title = columns[index].title;
                  const tip = columns[index]?.tip;
                  const span = columns[index]?.span;
                  const onRefresh = columns[index]?.onRefresh;
                  const isLoading = columns[index]?.isLoading;

                  if (hasTitle && index === 0) {
                    // 筛选列表第一个元素变成列表标题。
                    result.push(
                      <Col
                        key={`${id}-${title}-${this_meta}-d`}
                        className="hide-for-bs m-th"
                      >
                        {e?.[key]}
                      </Col>
                    );
                    result.push(
                      <Col
                        key={`${id}-${title}-${this_meta}-m`}
                        className="as-resp-row hide-for-m"
                        xs={24}
                        md={24}
                        lg={span || defaultSpan}
                        xl={span || defaultSpan}
                        xxl={span || defaultSpan}
                      >
                        <span className="hide-for-bs">
                          {key} <i></i> {tip ? addTip(tip) : ""}
                        </span>{" "}
                        {e?.[key]}
                      </Col>
                    );
                  } else {
                    result.push(
                      <Col
                        key={`${id}-${title}-${this_meta}-r`}
                        className="as-resp-row"
                        xs={24}
                        md={24}
                        lg={span || defaultSpan}
                        xl={span || defaultSpan}
                        xxl={span || defaultSpan}
                      >
                        <span className="hide-for-bs">
                          {title} <i></i> {tip ? addTip(tip) : ""}
                        </span>
                        <span>
                          {" "}
                          {e?.[key]}{" "}
                          {onRefresh ? (
                            <SyncOutlined
                              spin={isLoading}
                              onClick={() => onRefresh()}
                              style={{ color: "#59D89D", cursor: "pointer" }}
                            />
                          ) : (
                            <></>
                          )}
                        </span>
                      </Col>
                    );
                  }
                }
              } else {
                result.push(
                  <Row key={`${id}-${this_meta}-loading-r`}>
                    <p>Loading....</p>
                  </Row>
                );
              }
              return (
                <Row key={this_meta + "-" + id + "-fd"} className="tb-only-m flex-tb">
                  {result}
                </Row>
              );
            })
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default FlexTable;
