import z from "./NameServiceInterface_z"
import u from "./NameServiceInterface_u"

let realABI:any = null

// @ts-ignore
if(window._zbc_app_ver === "u"){
  realABI = u
}else{
  realABI = z
}

export default realABI