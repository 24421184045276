import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Tooltip,
  Alert,
  Progress,
  Pagination,
} from "antd";
import { HeaderStaking } from "../../components";
import FlexTable from "../../components/FlexTable";
import "./index.scss";
import { CustomerFooter } from "../../components";
import { useWallet } from "../../store/wallet-context";
import { BigNumber, ethers } from "ethers";
import { useInvitationCenter } from "../../store/invitation-center-context";
import { useZbcPool } from "../../store/zbc-pool-context";
import { EyeOutlined } from "@ant-design/icons";
import { formatAmount } from "../../utils/utils";
import copy from "clipboard-copy";
import { useBackend } from "../../store/backend";
import { useTranslation } from "react-i18next";
import { useProvider } from "wagmi";

const Refer: React.FC = () => {
  // @ts-ignore
  let ver = window._zbc_app_ver;

  const provider = useProvider();
  const wallet = useWallet();
  let [symbol, setSymbol] = useState("");
  useEffect(() => {
    if (provider?.chains) {
      setSymbol(provider?.chains[0]?.nativeCurrency.symbol || "");
    }
  }, [provider.chains]);
  const invitationCenter = useInvitationCenter();
  const ZbcPool = useZbcPool();
  const [modal, openModal] = useState(false);
  const backend = useBackend();
  const { t } = useTranslation();
  const rd_columns = [
    {
      key: "validator_referrals",
      title: "Validator referrals",
    },
    {
      key: "referrals_staked",
      title: "Referrals staked",
    },
    {
      key: "referral_code",
      title: "Referral code",
    },
  ];

  const buyInvitationCode = async () => {
    if (ver === "u") {
      // ver u
      if (ZbcPool.enableInviteeBonusWrite) {
        const { signature, price } = backend.ZbcPriceRaw;
        await ZbcPool.enableInviteeBonusWrite({
          recklesslySetUnpreparedArgs: [
            price,
            signature,
            ethers.constants.HashZero,
          ],
          recklesslySetUnpreparedOverrides: {
            value: ZbcPool.enableInviteeBonusFee,
          },
        }).catch(() => {});
      }
    } else {
      // ver z
      if (ZbcPool.enableInviteeBonusWrite) {
        await ZbcPool.enableInviteeBonusWrite({
          recklesslySetUnpreparedArgs: [ethers.constants.HashZero],
          recklesslySetUnpreparedOverrides: {
            value: ZbcPool.globalConfig.enableInviteeBonusZbcFee,
          },
        }).catch(() => {});
      }
    }

    openModal(false);
  };

  const not_enough_to_buy_invitation_code = useMemo(() => {
    let result = true;
    if (ver === "u") {
      // ver u
      try {
        if (wallet.nativeBalance?.value.gte(ZbcPool?.enableInviteeBonusFee))
          result = false;
      } catch (error) {
        //
      }
      return result;
    } else {
      // ver z
      if (
        wallet.nativeBalance?.value.gte(
          ZbcPool.globalConfig.enableInviteeBonusZbcFee
        )
      ) {
        result = false;
      }
      return result;
    }
  }, [
    ZbcPool?.enableInviteeBonusFee,
    ZbcPool.globalConfig.enableInviteeBonusZbcFee,
    ver,
    wallet.nativeBalance?.value,
  ]);

  const [referralsStaked, setReferralsStaked] = useState("--");

  useEffect(() => {
    let totalStaked = BigNumber.from(0);
    try {
      invitationCenter?.inviterTable?.forEach((e: any, i: number) => {
        if (ver === "u") {
          totalStaked = totalStaked.add(e.stakedUsdAmount);
        } else {
          totalStaked = totalStaked.add(e.stakedZbcAmount);
        }
      });
      setReferralsStaked(formatAmount(totalStaked));
    } catch (error) {
      // error
      console.log("can't add referrals staked!");
    }
  }, [invitationCenter?.inviterTable, ver]);

  // console.log(ZbcPool.globalConfig);

  const popup = (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ fontSize: "1.2rem" }}>Referral code</h2>
      {ZbcPool.enableInviteeBonusFee && invitationCenter.isRegistered ? (
        `Generating Referral Code will cost  ${
          ZbcPool.enableInviteeBonusFee &&
          invitationCenter.isRegistered &&
          backend.ready
            ? formatAmount(
                parseFloat(
                  ethers.utils.formatEther(ZbcPool?.enableInviteeBonusFee)
                ) * parseFloat(backend.ZbcPriceFormated)
              )
            : "--"
        } ${symbol}`
      ) : (
        <></>
      )}

      {ver === "u" ? (
        <>
          {/* ver u  */}
          <div style={{ fontSize: "0.8rem", color: "green" }}>
            {ZbcPool.enableInviteeBonusFee && invitationCenter.isRegistered ? (
              <>
                <p>
                  ≈
                  {formatAmount(
                    ethers.utils.formatEther(ZbcPool.enableInviteeBonusFee)
                  )}
                  {symbol}
                </p>
                {/* <p
              style={{
                display: not_enough_to_buy_invitation_code ? "block" : "none",
                color:"red"
              }}
            >
              Insufficient ZBC balanc
            </p> */}
              </>
            ) : (
              "Please register first"
            )}
          </div>
        </>
      ) : (
        <>
          {/* ver z */}
          <div style={{ fontSize: "0.8rem", color: "green" }}>
            {invitationCenter.isRegistered ? (
              <>
                <p>
                  {formatAmount(
                    ethers.utils.formatEther(
                      ZbcPool.globalConfig.enableInviteeBonusZbcFee
                    )
                  )}
                  {symbol}
                </p>
                {/* <p
              style={{
                display: not_enough_to_buy_invitation_code ? "block" : "none",
                color:"red"
              }}
            >
              Insufficient ZBC balanc
            </p> */}
              </>
            ) : (
              "Please register first"
            )}
          </div>
        </>
      )}
    </div>
  );

  // console.log(ZbcPool.getPerson);

  let rd_data: Array<Record<string, any>> = [{ ready: false }];
  try {
    rd_data = wallet.accountAddress
      ? [
          {
            validator_referrals: formatAmount(
              invitationCenter.inviteeRecordsLength,
              { int: true, number: true }
            ),
            referrals_staked:
              ver === "u" ? "$ " + referralsStaked : referralsStaked + "ZBC",
            referral_code: (
              <div>
                <span>
                  {ZbcPool?.getPerson?.inviteeBonusEnableFlag ? (
                    <>
                      <span style={{ paddingRight: "10px" }}>
                        {ethers.utils.parseBytes32String(
                          invitationCenter?.inviterRecords?.invitationCode!
                        )}
                      </span>
                      {/* <Tooltip trigger="click" title={t("copy_succeeded")}>
                        <Button
                          onClick={() => {
                            copy(
                              ethers.utils.parseBytes32String(
                                invitationCenter?.inviterRecords
                                  ?.invitationCode!
                              )
                            );
                          }}
                          type="primary"
                        >
                          {t("copy_code")}
                        </Button>
                      </Tooltip> */}
                      <Tooltip trigger="click" title={t("copy_succeeded")}>
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            if (ver === "u") {
                              // ver u
                              copy(
                                window.location.origin +
                                  "/" +
                                  "zbc-u" +
                                  "/staking?code=" +
                                  ethers.utils.parseBytes32String(
                                    invitationCenter?.inviterRecords
                                      ?.invitationCode!
                                  )
                              );
                            } else {
                              // ver z
                              copy(
                                window.location.origin +
                                  "/" +
                                  "zbc-z" +
                                  "/staking?code=" +
                                  ethers.utils.parseBytes32String(
                                    invitationCenter?.inviterRecords
                                      ?.invitationCode!
                                  )
                              );
                            }
                          }}
                          type="primary"
                        >
                          {t("copy_link")}
                        </Button>
                      </Tooltip>
                      {/* <Button onClick={() => openModal(true)} type='primary'>Generate</Button> */}
                    </>
                  ) : (
                    <Button onClick={() => openModal(true)} type="primary">
                      {t("Generate")}
                    </Button>
                  )}
                </span>
              </div>
            ),
            ready: true,
          },
        ]
      : [];
  } catch (error) {
    console.log("error when compute rd_data", error);
  }

  const ch_columns = wallet.accountAddress
    ? [
        // {
        //   key: "index",
        //   title: t("Index"),
        //   span: 3,
        // },
        {
          key: "address",
          title: t("Address"),
          span: 15,
        },
        {
          key: "amount",
          title: "Stake amount",
          span: 6,
        },
      ]
    : [];

  const formatRH = (): Record<string, any>[] => {
    let result: Record<string, any>[] = [];
    // console.log(invitationCenter.isRegistered);

    try {
      invitationCenter?.inviterTable?.forEach((e: any, i: number) => {
        let row = {};
        row = {
          index: i + 1,
          key: e.who + i,
          address: (
            <>
              <span className="hide-for-m">{e.who}</span>
              <Tooltip trigger="click" title={e.who}>
                <span className="hide-for-bs">
                  {e.who.slice(0, 6) +
                    "...." +
                    e.who.substring(e.who.length - 4)}
                  <EyeOutlined style={{ paddingLeft: "5px" }} />
                </span>
              </Tooltip>
            </>
          ),
          amount: `${formatAmount(
            e.cumulativeZbcPut.sub(e.cumulativeZbcTaken).gt(0)
              ? e.cumulativeZbcPut.sub(e.cumulativeZbcTaken)
              : 0
          )}ZBC`,
          ready: true,
          amountNumber: parseFloat(
            e.cumulativeZbcPut.sub(e.cumulativeZbcTaken)
          ),
        };
        if (true) {
          result.push(row);
        }
        result.sort((a, b) => b.amountNumber - a.amountNumber);
      });
    } catch (error) {
      console.log("error when compute rh_data");
      result.push({ ready: false });
    }
    return result;
  };

  const ch_data = wallet.accountAddress ? formatRH() : [];
  const [page, setPage] = useState(1);
  return (
    <div className="stake-page refer-page">
      <HeaderStaking />
      <div className="container">
        <div style={{ padding: "30px 0" }}></div>
        {/* use components rather than fragments! */}
        <Row justify="center" style={{ padding: "30px 0 40px 0" }}>
          <Col span={24} className="card-bt40">
            <Card bordered={false} title={t("Referral")}>
              {wallet.accountAddress ? (
                <>
                  <FlexTable
                    id={"rd"}
                    dataSource={rd_data}
                    columns={rd_columns}
                  />
                </>
              ) : (
                <>
                  <Alert message="No Wallet Connection" type="success" />
                </>
              )}
            </Card>
          </Col>
          <Col span={24} className="card-bt40">
            {/* <Card bordered={false} title="Referral History" extra={<p className='hide-for-m' style={{ color: "#888" }}>Total claimed <span style={{ color: "#fff" }}>100000</span>  ZBC</p>}>
              <span className='hide-for-bs' style={{ color: "#888" }}>Total claimed <span style={{ color: "#fff" }}>100000</span>  ZBC</span> */}
            <Card bordered={false} title={"Referral history"}>
              {wallet.accountAddress ? (
                <>
                  {invitationCenter.inviterTableLoadingPercent === 100 ? (
                    <>
                      <FlexTable
                        id={"ch"}
                        hasTitle
                        dataSource={ch_data.slice((page - 1) * 10, page * 10)}
                        columns={ch_columns}
                      />
                      <Pagination
                        showSizeChanger={false}
                        current={page}
                        onChange={(page) => setPage(page)}
                        total={ch_data.length}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Progress
                        strokeColor="#59D89D"
                        type="circle"
                        percent={invitationCenter.inviterTableLoadingPercent}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Alert message="No Wallet Connection" type="success" />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <CustomerFooter></CustomerFooter>
      <Modal
        open={modal}
        onCancel={() => openModal(false)}
        onOk={buyInvitationCode}
        centered
        closeIcon={<></>}
        maskClosable={false}
        keyboard={false}
        confirmLoading={ZbcPool.enableInviteeBonusIsLoading}
        okText={(() => {
          if (!invitationCenter.isRegistered) return t("OK");
          if (not_enough_to_buy_invitation_code)
            return "Insufficient ZBC balance";
          return t("OK");
        })()}
        okButtonProps={{
          disabled:
            !invitationCenter.isRegistered || not_enough_to_buy_invitation_code,
        }}
      >
        {popup}
      </Modal>
    </div>
  );
};

export default Refer;
