import React, { useState } from "react";
import "./index.scss";
import heroImg from "../../assets/images/hero-img.png";
import { Col, Row, Slider, InputNumber, Button } from "antd";
import step1Svg from "../../assets/images/step1.svg";
import step2Svg from "../../assets/images/step2.svg";
import step3Svg from "../../assets/images/step3.svg";
import apy_8 from "../../assets/images/8_apy.svg";
import apy_18 from "../../assets/images/18_apy.svg";
import apy_28 from "../../assets/images/28_apy.svg";
import apy_38 from "../../assets/images/38_apy.svg";
import logo_1 from "../../assets/images/logo/logo-1.png";
import logo_2 from "../../assets/images/logo/logo-2.png";
import logo_3 from "../../assets/images/logo/logo-3.png";
import logo_4 from "../../assets/images/logo/logo-4.png";
import logo_5 from "../../assets/images/logo/logo-5.png";
import logo_6 from "../../assets/images/logo/logo-6.png";
import logo_7 from "../../assets/images/logo/logo-7.png";
import { HeaderLandingPage } from "../../components";
import { CustomerFooter } from "../../components";
import { Portfolio } from "../LandingPage/components";
import { useNavigate } from "react-router-dom";
import LaunchButton from "../../components/LaunchButton";
const LandingPage = () => {
  const [inputValue, setInputValue] = useState(6);
  const onChange = (newValue: number | null) => {
    setInputValue(newValue || 0);
    const html = document.getElementsByTagName("html");
    html[0].style.fontSize = 20 * (newValue || 0) + "%";
  };
  const navigate = useNavigate();
  // eslint-disable-next-line no-sparse-arrays
  const [plans] = useState([
    {
      package: "Package 1",
      apy: "8",
      title: "Original Annual Yield",
      span: "Staking 18 days",
      icon: apy_8,
      plan: "1",
    },
    {
      package: "Package 2",
      apy: "18",
      title: "Original Annual Yield",
      span: "Staking 88 days",
      icon: apy_18,
      plan: "2",
    },
    {
      package: "Package 3",
      apy: "28",
      title: "Original Annual Yield",
      span: "Staking 188 days",
      icon: apy_28,
      plan: "3",
    },
    ,
    {
      package: "Package 4",
      apy: "38",
      title: "Original Annual Yield",
      span: "Staking 888 days",
      icon: apy_38,
      plan: "4",
    },
  ]);

  const [logo] = useState([
    { name: "1", url: logo_1, size: [92, 82] },
    { name: "2", url: logo_2, size: [163, 70] },
    { name: "3", url: logo_3, size: [224, 34] },
    { name: "4", url: logo_4, size: [125, 41] },
    { name: "5", url: logo_5, size: [209, 43] },
    { name: "6", url: logo_6, size: [167, 36] },
    { name: "7", url: logo_7, size: [114, 41] },
  ]);

  return (
    <>
      <HeaderLandingPage />

      <div className="index-page-new">
        <div className="hero">
          <div className="space">{/* space */}</div>
          <h2>Stable Yield Made Easy</h2>
          <small>
            Enjoy steady and secure returns with Zebec’s enterprise-grade $ZBC
            Bonds
          </small>
          <div>
            <img src={heroImg} alt="" />
          </div>
          <div className="space">{/* space */}</div>
        </div>
        <div className="page p-2">
          <div className="container">
            <div className="more">
              <h2>How it Works</h2>
              <small>
                $ZBC Bonds are enterprise-grade stable yield products offered by
                Zebec. Because $ZBC Bonds offer fixed-returns, holders have no
                questions as to how much their returns will be.
              </small>
              <Row gutter={20}>
                <Col sm={24} md={24} xs={24} lg={8} xl={8} xxl={8}>
                  <div>
                    <img src={step1Svg} alt="" />
                    <h3>Bridge ZBC</h3>
                    <div>
                      <p>
                        Bridge $ZBC from BNB Chain or Solana to Nautilus Chain
                        to purchase a $ZBC Bond.
                      </p>
                    </div>
                    <span className="btn-like">Step 1</span>
                  </div>
                </Col>
                <Col sm={24} md={24} xs={24} lg={8} xl={8} xxl={8}>
                  <div>
                    <img src={step2Svg} alt="" />
                    <h3>Choose Package</h3>
                    <div>
                      <p>
                        To stake your $ZBC, choose from four different bond
                        offerings that best suit your needs.
                      </p>
                      <span className="btn-like">Step 2</span>
                    </div>
                  </div>
                </Col>
                <Col sm={24} md={24} xs={24} lg={8} xl={8} xxl={8}>
                  <div>
                    <img src={step3Svg} alt="" />
                    <h3>Claim Rewards</h3>
                    <div>
                      <p>
                        When your bond expires, claim your rewards. The longer
                        your bond, the greater your returns.
                      </p>
                      <span className="btn-like">Step 3</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="launch">
                <Button
                  onClick={() => navigate("/staking")}
                  type="default"
                  size="large"
                >
                  Launch App
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="page p-3">
          <div className="container">
            <h2>Choose the Right Bond for You</h2>
            <small>
              Zebec’s four $ZBC Bond offerings allow clients to match to their
              needed and easily calculate their returns.
            </small>
            <Row>
              {plans.map((e) => (
                <Col
                  sm={24}
                  md={24}
                  xs={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  key={e?.apy}
                >
                  <LaunchButton plan={e?.plan}>
                    <div className="plans">
                      <img src={e?.icon} alt="" className="card" />
                      <div className="flow">
                        <div className="package">
                          <span>{e?.package}</span>
                        </div>
                        <h4 className="title">{e?.title}</h4>
                        <div className="apy">
                          <span>{e?.apy}</span>
                          <span style={{ fontSize: "36px" }}>%</span>
                        </div>
                      </div>
                      <div className="right"></div>
                      <div className="bottom">
                        {e?.span}
                        <svg
                          className="arrow"
                          width="37"
                          height="27"
                          viewBox="0 0 37 27"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            d="M0 13.5H33.5M33.5 13.5L22 2M33.5 13.5L22 25"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </LaunchButton>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="page p-4">
          <div className="container">
            <h2>Bridge ZBC with Nautilus Bridge for Staking</h2>
            <small>
              ZBC Bonds are only offered on Nautilus Chain. It’s easy to bridge
              $ZBC purchased on BNB Chain or Solana from major exchanges to
              Nautilus using Nautilus Bridge.
            </small>
            {/* <div className="intro">
              <p>
                Buy ZBC on major exchanges and transfer them over to Nautilus
                using the Nautilus bridge. Once on Nautilus, you can use this
                platform for easy staking on Web3’s first modular, L3 chain.
              </p>
            </div> */}
            <div className="more">
              <Button
                onClick={() => window.open("https://docs.zbcstaking.com/guide")}
                type="default"
                size="large"
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
        <div className="page p-5">
          <small>Finance flow has been featured on</small>
          <div className="logo-box">
            {logo.map((e) => (
              <div
                className="logo"
                style={{
                  backgroundImage: `url(${e.url})`,
                  backgroundSize: `${Math.floor(
                    e.size[0] / 1.8
                  )}px ${Math.floor(e.size[1] / 1.8)}px`,
                }}
                key={e.name}
              ></div>
            ))}
          </div>
        </div>
        <Portfolio />
        <Row className="dev-zoom">
          <Col offset={4} span={12}>
            <Slider
              min={1}
              max={20}
              onChange={onChange}
              value={typeof inputValue === "number" ? inputValue : 0}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={1}
              max={20}
              style={{ margin: "0 16px" }}
              value={inputValue}
              onChange={onChange}
            />
          </Col>
        </Row>
      </div>
      <CustomerFooter />
    </>
  );
};

export default LandingPage;
