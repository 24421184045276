import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NameserviceKeys, useNameservice } from "./nameservice-context";
import invitationCenterAbi from "../abi/InvitationCenterInterface";
import zbcPoolAbi from "../abi/ZBCPoolInterface";
import {
  // useContract,
  useContractRead,
  // useProvider,
} from "wagmi";
import { ethers } from "ethers";
import { useWallet } from "./wallet-context";
import { multicallOnce } from "../utils/utils";

type InvitationCenterContextType = {
  isRegistered: boolean | undefined;
  inviterRecords:
    | {
        who: `0x${string}`;
        inviter: `0x${string}`;
        invitationCode: `0x${string}`;
      }
    | undefined;
  rootInviter:
    | {
        who: `0x${string}`;
        inviter: `0x${string}`;
        invitationCode: `0x${string}`;
      }
    | undefined;
  inviteHistory: any;
  inviterTable: any;
  inviteeRecordsLength: any;
  inviterTableLoadingPercent: number;
};

const InvitationCenterContext = createContext<InvitationCenterContextType>({
  isRegistered: undefined,
  inviterRecords: undefined,
  rootInviter: undefined,
  inviteHistory: undefined,
  inviterTable: [],
  inviteeRecordsLength: undefined,
  inviterTableLoadingPercent: 0,
});

export const useInvitationCenter = () => useContext(InvitationCenterContext);

const useProvideInvitationCenter = () => {
  const { accountAddress } = useWallet();
  const ns = useNameservice();
  // const provider = useProvider();
  const [inviterTableLoadingPercent, setInviterTableLoadingPercent] =
    useState(0);
  // const zbc = useContract({
  //   address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.AddressZero,
  //   abi: zbcPoolAbi,
  //   signerOrProvider: provider,
  // });
  const invitationCenterContract = {
    address: ns?.[NameserviceKeys.InvitationCenter] as `0x${string}`,
    abi: invitationCenterAbi,
  };

  const { data: isRegistered } = useContractRead({
    ...invitationCenterContract,
    watch: true,
    enabled: !!invitationCenterContract.address && !!accountAddress,
    functionName: "isRegistered",
    args: [accountAddress!],
  });

  const { data: inviterRecords } = useContractRead({
    ...invitationCenterContract,
    watch: true,
    enabled:
      !!invitationCenterContract.address && !!isRegistered && !!accountAddress,
    functionName: "inviterRecords",
    args: [accountAddress!],
  });

  // contains default inviterCode
  const { data: rootInviter } = useContractRead({
    ...invitationCenterContract,
    watch: true,
    enabled: !!invitationCenterContract.address,
    functionName: "getRootInviter",
  });

  const { data: inviteeRecordsLength } = useContractRead({
    ...invitationCenterContract,
    watch: true,
    enabled:
      !!invitationCenterContract.address && !!isRegistered && !!accountAddress,
    functionName: "inviteeRecordsLength",
    args: [accountAddress!],
  });

  const _records = useCallback(()=>{
    let records = [];
    try {
      if (inviteeRecordsLength) {
        for (let index = 0; index < inviteeRecordsLength.toNumber(); index++) {
          records.push({
            abi: invitationCenterAbi,
            args: [
              accountAddress || ethers.constants.AddressZero,
              ethers.BigNumber.from(index),
            ],
            address: ns?.[NameserviceKeys.InvitationCenter] as `0x${string}`,
            functionName: "inviteeRecordsAt",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setRecords(records)
  },[accountAddress, inviteeRecordsLength, ns])


  const [records,setRecords] = useState<any>([])

  useEffect(()=>{
    _records()
  },[_records])

  const [inviteeRecordsAt, setInviteeRecordsAt] = useState([]);

  const _inviteeRecordsAt = useCallback(async () => {
    let _data = [];
    if (records) {
      _data = await multicallOnce(records, (percent: number) =>
        setInviterTableLoadingPercent(percent * 0.32)
      );
    }
    setInviteeRecordsAt(_data);
  }, [records]);

  useEffect(() => {
    _inviteeRecordsAt();
  }, [_inviteeRecordsAt]);

  const recordsAddresses = useMemo(() => {
    return inviteeRecordsAt?.map((e, i) => {
      return {
        abi: invitationCenterAbi,
        args: [e],
        address: ns?.[NameserviceKeys.InvitationCenter] as `0x${string}`,
        functionName: "inviterRecords",
      };
    });
  }, [inviteeRecordsAt, ns]);

  const [inviteHistory, setInviteHistory] = useState([]);

  const _inviteHistory = useCallback(async () => {
    let _data = [];

    if (recordsAddresses) {
      _data = await multicallOnce(recordsAddresses, (percent: number) =>
        setInviterTableLoadingPercent(percent * 0.32 + 32)
      );
    }
    setInviteHistory(_data);
  }, [recordsAddresses]);

  useEffect(() => {
    _inviteHistory();
  }, [_inviteHistory]);

  // const { data: inviteHistory } = useContractReads({
  //   contracts: recordsAddresses,
  //   watch: true,
  //   enabled:
  //     !!invitationCenterContract.address && !!isRegistered && !!accountAddress,
  // });

  const [inviterTable, setInviterTable] = useState([]);

  const updateInvterTable = useCallback(async () => {
    let result: any = [];
    try {
      if (inviteHistory) {
        let contracts = inviteHistory.map((e) => {
          return {
            address: ns?.[NameserviceKeys.ZbcPool] as `0x${string}`,
            abi: zbcPoolAbi,
            functionName: "getPerson",
            // @ts-ignore
            args: [e.who],
          };
        });

        const peoples = await multicallOnce(contracts, (percent: number) =>
          setInviterTableLoadingPercent(percent * 0.33 + 67)
        );

        let peoples_filted = [];
        if (Array.isArray(peoples)) {
          peoples_filted = peoples.filter(
            (e) => e.who !== ethers.constants.AddressZero
          );
        }
        // const data = await zbc?.getPerson(element?.who as `0x${string}`);
        result = peoples_filted;
      }
    } catch (error) {
      console.log(error);
    }

    console.log(result);
    

    setInviterTable(result);
  }, [inviteHistory, ns]);

  useEffect(() => {
    if (accountAddress) {
      updateInvterTable();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ updateInvterTable]);

  // const { data: inviterTable } = useContractReads({
  //   contracts: inviterPersonInfoTable,
  //   watch: true,
  //   enabled:
  //     !!invitationCenterContract.address && !!isRegistered && !!accountAddress,
  // });

  return {
    isRegistered,
    inviterRecords,
    rootInviter,
    inviteHistory,
    inviterTable,
    inviteeRecordsLength,
    inviterTableLoadingPercent,
  };
};

const InvitationCenterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value = useProvideInvitationCenter();
  return (
    <InvitationCenterContext.Provider value={value}>
      {children}
    </InvitationCenterContext.Provider>
  );
};

export default InvitationCenterProvider;
