import { multicall } from "@wagmi/core";
import { BigNumber, ethers } from "ethers";
// import 'dayjs/locale/en'
// import 'dayjs/locale/ms'
// import 'dayjs/locale/ko'
// import 'dayjs/locale/ru'
// import 'dayjs/locale/es'
// import 'dayjs/locale/hi'
// import 'dayjs/locale/vi'
// import 'dayjs/locale/ja'
// import 'dayjs/locale/ar'
// import 'dayjs/locale/id'
// import 'dayjs/locale/zh-cn'

export const truncAddress = (addr: string) => {
  if (addr && addr.length === 42 && addr !== ethers.constants.AddressZero) {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
  }
  return "-";
};

export function truncateNumber(str: string, maxDecimalDigits = 4) {
  if (str.includes(".")) {
    const parts = str.split(".");
    let fracturePart = parts[1].slice(0, maxDecimalDigits);
    while (fracturePart.endsWith("0") && fracturePart.length > 1) {
      fracturePart = fracturePart.slice(0, -1);
    }
    return parts[0] + "." + fracturePart;
  }

  return str;
}

export function stringToBytes32(input: string): string {
  return ethers.utils.formatBytes32String(input);
}

export function bytes32ToString(input: string): string {
  return ethers.utils.parseBytes32String(input);
}

export function hexify(input: string): string {
  // return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(input));
  const result = ethers.utils.formatBytes32String(input);
  // console.log('hexify', input, result);
  return result;
}

export async function callWithToastError<T>(
  func: () => Promise<T>,
  toast: any,
  message: string
): Promise<T | null> {
  try {
    return await func();
  } catch (e: unknown) {
    console.error(e);
    if (e instanceof Error) {
      toast({
        // description: e.message,
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    return null;
  }
}

export function formatAmount(
  input: BigNumber | string | number | undefined,
  config?: Record<string, any> | undefined
): string {
  const precision = config?.precision || 2;

  if (typeof input === "undefined") return "--";
  if (typeof input === "string") {
    if (input === "") {
      return "--";
    }
    let amount = input.split(".");
    if (config?.int) return amount[0];

    if (amount[1]) {
      return amount[0] + "." + amount[1].slice(0, precision);
    }
    return input;
  } else if (typeof input === "number") {
    if (config?.int) return Math.floor(input) + "";
    if (input !== 0 && Math.abs(input) <= 0.00001) {
      return formatAmount(input.toFixed(precision), config);
    }
    return formatAmount(
      Math.floor(input * Math.pow(10, precision)) / Math.pow(10, precision) +
        "",
      config
    );
  } else {
    if (config?.number) {
      try {
        const bn = BigNumber.from(input);
        return formatAmount(bn.toString(), config);
      } catch (error) {}
    }
    try {
      const st = ethers.utils.formatEther(input);
      return formatAmount(st, config);
    } catch (error) {}
  }
  return "--";
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export async function multicallOnce(contracts: any[], percent?: Function) {
  let contractsCopy = [...contracts];
  let length = contracts.length;
  let results: any = [];
  while (contractsCopy.length > 0) {
    let trunk = contractsCopy.splice(0, 20);
    const data = await multicall({ contracts: trunk });
    results = results.concat(data);
    await sleep(1000)
    if (percent)
      percent(Math.floor(((length - contractsCopy.length) / length) * 100));
  }
  if (percent) percent(100);
  return results;
}
export function multicall_contract(config: {
  abi: any;
  address: any;
  functionName: any;
  percent?:Function
}) {
  return (data: any) => {
    const contracts = data.map((e: any) => {
      return {
        ...config,
        // @ts-ignore
        args: [e],
      };
    });
    return multicallOnce(contracts,config?.percent);
  };
}
