import { Layout, Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import "./index.scss";
import { useState } from "react";
import LangButton from "../LangButton";
import { useTranslation } from "react-i18next";
import LaunchButton from "../LaunchButton";
const { Header } = Layout;

export const HeaderLandingPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [showNav, setNav] = useState(false);

  const onClickLogo = () => {
    navigate("/");
  };

  return (
    <Header className="my-nav">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <i onClick={onClickLogo} className="logo"></i>
        <div className="lang-btn">
          <LangButton />
        </div>
        {showNav ? (
          <CloseOutlined
            onClick={() => setNav(!showNav)}
            style={{ display: "none" }}
            className="m-handler"
          />
        ) : (
          <MenuOutlined
            onClick={() => setNav(!showNav)}
            style={{ display: "none" }}
            className="m-handler"
          />
        )}
        <div>
          <LaunchButton>
            <Button
              size="large"
              style={{
                padding: "0 30px",
              }}
              type="primary"
            >
              {t("App")}
            </Button>
          </LaunchButton>
        </div>
      </div>
      <Space
        style={{ display: "none" }}
        className={`nav-dropdown ${showNav ? "show-nav" : ""}`}
      >
        <div className="lang-btn-pc">
          <LangButton />
        </div>
        <div style={{ display: "none" }}>
          <Button type="text">{t("how_it_works")}</Button>
          <Button type="text">{t("FAQ")}</Button>
          <Button type="text">{t("More")}</Button>
        </div>
        <Button
          size="large"
          style={{ width: "146px" }}
          type="primary"
        >
          {t("App")}
        </Button>
      </Space>
    </Header>
  );
};
