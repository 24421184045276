import React from "react";
// import bank from "../../../../assets/images/bank.svg"
// import safety from "../../../../assets/images/safety.svg"
import { Row, Col } from "antd";
export const Portfolio: React.FC = () => {
  const data = [
    {
      key: "1",
      title: (
        <h2>
          One Easy <br /> Dashboard
        </h2>
      ),
      text: <p>Manage your stake on a simple dashboard</p>,
      // icon: safety
    },
    {
      key: "2",
      title: (
        <h2>
          Your Stake <br /> Your Timing
        </h2>
      ),
      text: <p>Withdraw your delegation at any time</p>,
      // icon: bank
    },
    {
      key: "3",
      title: (
        <h2>
          Time Weighted Rewards <br /> Earn More
        </h2>
      ),
      text: <p>The longer you lock your ZBC the more you earn</p>,
      // icon: safety
    },
    {
      key: "4",
      title: (
        <h2>
          Join the <br />
          Community
        </h2>
      ),
      text: <p>Join Zebec’s social media for news and updates</p>,
      // icon: bank
    },
  ];

  return (
    <div className="page p-6 my-cards">
      <div className="container">
        <h2>
          The Future of Global <br /> Payment Infrastructure
        </h2>
        <Row>
          {data.map((e) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                xxl={6}
                key={e.key}
                className="card"
              >
                <div>
                  {e.title}
                  {e.text}
                  {/* <img src={e.icon} alt="" /> */}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
