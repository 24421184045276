const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "calcAdditionRewardRateInMillion",
    outputs: [
      {
        internalType: "uint256",
        name: "additionalRewardRateInMillion",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "lockTimespan",
        type: "uint256",
      },
    ],
    name: "determineBillApy",
    outputs: [
      {
        internalType: "uint256",
        name: "apyInMillion",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "inviterCode",
        type: "bytes32",
      },
    ],
    name: "enableInviteeBonus",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "zbcPriceFixedPoint18",
        type: "uint256",
      },
    ],
    name: "enableInviteeBonusFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getActiveBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getActivePersonLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "billNumber",
        type: "uint256",
      },
    ],
    name: "getBill",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "zbcAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "efficientUsd",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lockTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "finishedTimestamp",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "getRewardFinished",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "apyInMillion",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeUsdTaken",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeZbcTaken",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "withdrawCapitalFinished",
            type: "bool",
          },
        ],
        internalType: "struct ZbcPoolType.Bill",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getGlobalConfig",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "oneDay",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "enableInviteeBonusUsdtFee",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeStakedZbc",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeStakedEfficientUsd",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.Config",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getPerson",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "cumulativeUsdPut",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeZbcPut",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeUsdTaken",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeZbcTaken",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "inviteeBonusEnableFlag",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "zepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "inviteeZepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "transactionNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedUsdAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "inviteeStakedUsdAmount",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.Person",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256[]",
        name: "billNumbers",
        type: "uint256[]",
      },
    ],
    name: "getReward",
    outputs: [
      {
        internalType: "uint256",
        name: "totalZbcReward",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "inviteeZepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "transactionNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
        ],
        internalType: "struct ZbcPoolType.SyncAdditionalApyParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getSyncAdditionalApyData",
    outputs: [
      {
        internalType: "uint256",
        name: "zepochNumber",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "inviteeZepochNumber",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "transactionNumber",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getWithdrawnBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
    ],
    name: "getZbcPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "zbcAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lockTimespan",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "inviterCode",
        type: "bytes32",
      },
    ],
    name: "stake",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "inviteeZepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "transactionNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
        ],
        internalType: "struct ZbcPoolType.SyncAdditionalApyParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
    ],
    name: "syncAdditionalApy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "inviteeZepochNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "transactionNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
        ],
        internalType: "struct ZbcPoolType.SyncAdditionalApyParam",
        name: "param",
        type: "tuple",
      },
    ],
    name: "syncAdditionalApyParamDigest",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "syncAdditionalApyParamTypeHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "usd",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
    ],
    name: "usdToZbc",
    outputs: [
      {
        internalType: "uint256",
        name: "zbc",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ZbcPoolType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256[]",
        name: "billNumbers",
        type: "uint256[]",
      },
    ],
    name: "withdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "zbcPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ZbcPoolType.ZbcPriceParam",
        name: "param",
        type: "tuple",
      },
    ],
    name: "zbcPriceParamDigest",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "zbcPriceParamTypeHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "zbc",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
    ],
    name: "zbcToUsd",
    outputs: [
      {
        internalType: "uint256",
        name: "usd",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
] as const;

export default abi;
