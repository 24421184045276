export const Colors = {
  primary: '#59D89D',
  secondary: '#2C633C',
  alertSuccess: '#4ADE80',
  alertInfo: '#246BFD',
  alertWar: '#FACC15',
  alertErr: '#F75555',
  alertDisable: '#D8D8D8',
  alertDisableButton: '#6B5BAB',
  grey1: '#404040',
  grey2: '#B3B3B3',
  grey3: '#959595',
  grey4: '#656565',
  grey5: '#E4E4E4',
  dark1: '#1E1E1E',
  dark2: '#1B1B1B',
  dark3: '#212222',
  dark4: '#151515',
  dark5: '#1D1B27',
  black: '#000000',
  white: '#FFFFFF',
  red: '#F54336',
  pink: '#EA1E61',
  purple: '#9D28AC',
  deepPurple: '#673AB3',
  indigo: '#3F51B2',
  blue: '#1D6AFF',
  cyan: '#00BCD3',
  teal: '#009689',
  green: '#4AAF57',
  lightGreen: '#8BC255',
  lime: '#CDDC4C',
  yellow: '#FFEB4F',
  amber: '#3D311B',
  orange: '#C68B1A',
  deepOrange: '#FF5726',
  brown: '#7A5548',
  blueGrey: '#607D8A',
};
