// import { bsc } from "@wagmi/core/chains";
import React from "react";
import { WagmiConfig, createClient, configureChains } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { Chain } from "@wagmi/core";
// const nodeUrl = process.env.REACT_APP_NODE_URL;
// if (!nodeUrl) throw new Error("REACT_APP_NODE_URL not set");

// let nodeUrl = "https://api.nautilus.nautchain.xyz"
// console.log("nodeUrl is:", nodeUrl);

const _nautilusMainnet: Chain = {
  id: 22222,
  name: "Nautilus",
  network: "Nautilus",
  nativeCurrency: {
    decimals: 18,
    name: "Zebec",
    symbol: "ZBC",
  },
  rpcUrls: {
    public: { http: ["https://api.nautilus.nautchain.xyz"] },
    default: { http: ["https://api.nautilus.nautchain.xyz"] },
  },
  blockExplorers: {
    etherscan: { name: "NautScan", url: "https://nautscan.com" },
    default: { name: "NautScan", url: "https://nautscan.com" },
  },
  contracts: {
    multicall3: {
      address: "0x8Ab4bcC5a96e2c0e83C46e8eA6F2BF3677F112ab",
    },
  },
};

// 这是版本 Z
const _nautilusTestnet: Chain = {
  id: 22222,
  name: "Nautilus",
  network: "Nautilus",
  nativeCurrency: {
    decimals: 18,
    name: "Zebec",
    symbol: "ZBC",
  },
  rpcUrls: {
    public: { http: ["https://api.nautilus.nautchain.xyz"] },
    default: { http: ["https://api.nautilus.nautchain.xyz"] },
  },
  blockExplorers: {
    etherscan: { name: "NautScan", url: "https://nautscan.com" },
    default: { name: "NautScan", url: "https://nautscan.com" },
  },
  contracts: {
    multicall3: {
      address: "0xFbbd3F3B0A5441cD4f5a6abc13F055907B3AF4F2",
    },
  },
};

// const _nautilusTestnet: Chain = {
//   id: 88002,
//   name: "Proteus",
//   network: "Proteus",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Zebec",
//     symbol: "ZBC",
//   },
//   rpcUrls: {
//     public: { http: ["https://api.proteus.nautchain.xyz/solana"] },
//     default: { http: ["https://api.proteus.nautchain.xyz/solana"] },
//   },
//   blockExplorers: {
//     etherscan: { name: "NautScan", url: "https://proteus.nautscan.com/" },
//     default: { name: "NautScan", url: "https://proteus.nautscan.com/" },
//   },
//   contracts: {
//     multicall3: {
//       address: "0x358C25aa18e84088E2D5B8F627D8685d321eEb9d",
//     },
//   },
// };

let ver_string = window.location.pathname.split("/");
let v = ver_string[1] === "zbc-u" ? "u" : "z";

// @ts-ignore
window._zbc_app_ver = v;
// @ts-ignore
window._zbc_app_ver_name = v === "u" ? "zbc-u" : "zbc-z";

const _net = v === "u" ? _nautilusMainnet : _nautilusTestnet;

export const nautilusMainnet = {
  ..._net,
} as const satisfies Chain;

let provider = null;
if (v === "u") {
  const { provider: _provider } = configureChains(
    [_nautilusMainnet],
    [
      jsonRpcProvider({
        rpc: () => ({ http: "https://api.nautilus.nautchain.xyz" }),
      }),
    ]
  );
  provider = _provider;
  // nodeUrl = "https://api.nautilus.nautchain.xyz"
} else {
  const { provider: _provider } = configureChains(
    [_nautilusTestnet],
    [
      jsonRpcProvider({
        rpc: () => ({ http: "https://api.nautilus.nautchain.xyz" }),
      }),
    ]
  );
  provider = _provider;
}

const client = createClient({
  autoConnect: true,
  provider,
});

const WagmiProvider = ({ children }: { children: React.ReactNode }) => (
  <WagmiConfig client={client}>{children}</WagmiConfig>
);

export default WagmiProvider;
