import React, { useCallback, useEffect, useRef, useState } from "react";
import { useWallet } from "../../store/wallet-context";

import { Button, Modal, Spin } from "antd";
import { truncAddress } from "../../utils/utils";
import logo_metamask from "../../assets/images/wallets/meta-mask.jpg";
import logo_tp from "../../assets/images/wallets/TP.jpg";
import logo_c98 from "../../assets/images/wallets/C98.jpg";
import logo_bitget from "../../assets/images/wallets/Bitget.jpg";
import logo_okx from "../../assets/images/wallets/OKX.jpg";
import logo_trust from "../../assets/images/wallets/trust.jpg";
import { isMobile } from "react-device-detect";
import {
  MobileOutlined,
  DownloadOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
const WalletButton = () => {
  const { connect, disconnect, accountAddress, isConnected, isLoading } =
    useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal_state, set_modal_state] = useState("r_");

  const this_site = "zbcstaking.com";

  const walletConfig = useRef<Record<string, any>>({
    dmetamask: {
      name: "Meta Mask",
      url: "https://metamask.io/download/",
      deepLink: "https://metamask.app.link/dapp/" + this_site + "/",
    },
    dtp: {
      name: "Token Pocket",
      url: "https://www.tokenpocket.pro/zh/download/app",
      deepLink: "tpdapp://open?url=https://" + this_site,
    },
    dokx: {
      name: "OKX",
      url: "https://www.okx.com/web3",
      deepLink: "okx://wallet/dapp/details?dappUrl=https://" + this_site,
    },
    dc98: {
      name: "Coin98",
      url: "https://www.okx.com/web3",
      deepLink: "https://coin98.com/dapp/" + this_site + "/22222",
    },
    dtrust: {
      name: "trust",
      url: "https://trustwallet.com",
      deepLink:
        "https://link.trustwallet.com/open_url?url=https://" + this_site + "/",
    },
    dbitget: {
      name: "Bitget",
      url: "https://web3.bitget.com/en/wallet-download?type=2",
      deepLink: "https://bkcode.vip?action=dapp&url=https://" + this_site + "/",
    },
  });

  const try_metamask = useCallback(() => {
    const isMetamaskInstalled = () => {
      if (typeof window === "undefined") {
        return false;
      }

      if (window?.ethereum?.isMetaMask) {
        return true;
      }

      if (window?.ethereum?.providers?.some((p) => p.isMetaMask)) {
        return true;
      }

      return false;
    };
    if (isMetamaskInstalled()) {
      set_modal_state("r0");
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dmetamask.deepLink);
      set_modal_state("dmetamask");
    }
    return true;
  }, [connect]);

  useEffect(() => {
    if (!isConnected) {
      set_modal_state("r_");
      setIsModalOpen(false);
    }
  }, [isConnected]);

  const try_tp = useCallback(() => {
    // @ts-ignore
    if (
      typeof window !== "undefined" &&
      // @ts-ignore
      typeof window?.ethereum?.isTokenPocket !== "undefined"
    ) {
      // TP is installed!
      set_modal_state("r1");
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dtp.deepLink);
      set_modal_state("dtp");
    }
  }, [connect]);

  const try_coin98 = useCallback(() => {
    // @ts-ignore
    if (typeof window !== "undefined" && Boolean(window.coin98)) {
      // coin 98 is installed!
      set_modal_state("r2");
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dc98.deepLink);
      set_modal_state("dc98");
    }
  }, [connect]);

  const try_bitget = useCallback(() => {
    if (
      typeof window !== "undefined" &&
      // @ts-ignore
      typeof window?.bitkeep !== "undefined" &&
      // @ts-ignore
      typeof window?.bitkeep.ethereum !== "undefined"
    ) {
      set_modal_state("r3");
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dbitget.deepLink);
      set_modal_state("dbitget");
    }
  }, [connect]);

  const try_okx = useCallback(() => {
    if (
      typeof window !== "undefined" &&
      // @ts-ignore
      typeof window?.okxwallet !== "undefined"
    ) {
      set_modal_state("r4");
      // OKX is installed!
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dokx.deepLink);
      set_modal_state("dokx");
    }
  }, [connect]);

  const try_trust = useCallback(() => {
    // @ts-ignore
    if (typeof window?.ethereum?.isTrust !== "undefined") {
      set_modal_state("r5");
      connect();
    } else {
      if (isMobile) window.open(walletConfig.current.dtrust.deepLink);
      set_modal_state("dtrust");
    }
  }, [connect]);

  if (accountAddress && isConnected) {
    return (
      <Button
        style={{ width: "146px" }}
        size="large"
        type="primary"
        onClick={() => {
          disconnect({ onerror: () => {} });
          setIsModalOpen(false);
        }}
      >
        {truncAddress(accountAddress)}
      </Button>
    );
  }

  return (
    <>
      <Modal
        title="Connect with"
        maskClosable={false}
        keyboard={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          set_modal_state("r_");
        }}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{ display: modal_state[0] === "r" ? "flex" : "none" }}
          className="wallet-logo"
        >
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "0"}
            loading={modal_state[1] === "0" && isLoading}
            onClick={() => try_metamask()}
            style={{ backgroundImage: `url(${logo_metamask})` }}
          ></Button>
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "1"}
            loading={modal_state[1] === "1" && isLoading}
            onClick={() => try_tp()}
            style={{ backgroundImage: `url(${logo_tp})` }}
          ></Button>
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "2"}
            loading={modal_state[1] === "2" && isLoading}
            onClick={() => try_coin98()}
            style={{ backgroundImage: `url(${logo_c98})` }}
          ></Button>
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "3"}
            loading={modal_state[1] === "3" && isLoading}
            onClick={() => try_bitget()}
            style={{ backgroundImage: `url(${logo_bitget})` }}
          ></Button>
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "4"}
            loading={modal_state[1] === "4" && isLoading}
            onClick={() => try_okx()}
            style={{ backgroundImage: `url(${logo_okx})` }}
          ></Button>
          <Button
            type="text"
            disabled={isLoading && modal_state[1] !== "5"}
            loading={modal_state[1] === "5" && isLoading}
            onClick={() => {
              try_trust();
            }}
            style={{ backgroundImage: `url(${logo_trust})` }}
          ></Button>
        </div>
        {modal_state[0] === "d" ? (
          <>
            <div className="download-wallet">
              <h2>
                {isMobile ? (
                  <>
                    <Spin></Spin> Entering{" "}
                    {walletConfig.current[modal_state].name} ...
                  </>
                ) : (
                  walletConfig.current[modal_state].name + " not installed"
                )}
              </h2>

              <Button size="large" block type="primary">
                <DownloadOutlined />
                Download {walletConfig.current[modal_state].name} wallet
              </Button>
              <Button
                onClick={() => {
                  window.open(walletConfig.current[modal_state].deepLink);
                }}
                block
                type="primary"
                size="large"
              >
                <MobileOutlined />
                Open {walletConfig.current[modal_state].name} wallet
              </Button>
              <Button onClick={() => set_modal_state("r_")} block size="large">
                <LeftCircleOutlined />
                Choose another wallet
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
      <Button
        style={{ width: "146px" }}
        size="large"
        type="primary"
        onClick={() => {
          // connect({onerror:()=>{}})
          set_modal_state("r_");
          setIsModalOpen(true);
        }}
      >
        Connect
      </Button>
    </>
  );
};

export default WalletButton;
