import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useContractRead,
  useContractWrite,
  // useContractReads,
  useProvider,
  useContract,
} from "wagmi";
import ZbcAbi from "../abi/ZBCPoolInterface";
import { NameserviceKeys, useNameservice } from "./nameservice-context";
import { useWallet } from "./wallet-context";
import { BigNumber, ethers } from "ethers";
import { message, Modal } from "antd";
import { useBackend } from "./backend";
import { useTranslation } from "react-i18next";
import { multicall_contract } from "../utils/utils";


interface ZbcPoolContextType {
  globalConfig: any;
  ZbcPoolContract: any;
  enableInviteeBonusFee: any;
  getPerson: any;
  stakeWriteIsLoading: any;
  stakeWrite: any;
  enableInviteeBonusIsLoading: any;
  enableInviteeBonusWrite: any;
  getActiveBill: any;
  getWithdrawnBill: any;
  calcAdditionRewardRateInMillion: any;
  withdrawIsLoading: any;
  withdraw: any;
  getActivePersonLength: any;
  syncAdditionalApy: any;
  syncAdditionalApyIsLoading: any;
  getRewardIsLoading: any;
  getReward: any;
  modal: any;
  plans: any;
  msg: any;
  setActiveBillPage: any;
  setWithdrawnBillPage: any;
  activeBillPage: any;
  withdrawnBillPage: any;
  allActiveBills: any[];
  allWithdrawnBills: any[];
  activeBillsLoadingPercent: number;
  withdrawnBillsLoadingPercent: number;
}

const ZbcPoolContext = React.createContext<ZbcPoolContextType>({
  globalConfig: undefined,
  ZbcPoolContract: undefined,
  enableInviteeBonusFee: undefined,
  getPerson: undefined,
  stakeWriteIsLoading: undefined,
  stakeWrite: undefined,
  enableInviteeBonusIsLoading: undefined,
  enableInviteeBonusWrite: undefined,
  getActiveBill: undefined,
  getWithdrawnBill: undefined,
  calcAdditionRewardRateInMillion: undefined,
  withdrawIsLoading: undefined,
  withdraw: undefined,
  getActivePersonLength: undefined,
  syncAdditionalApy: undefined,
  syncAdditionalApyIsLoading: undefined,
  getRewardIsLoading: undefined,
  getReward: undefined,
  modal: null,
  msg: null,
  plans: undefined,
  setActiveBillPage: undefined,
  setWithdrawnBillPage: undefined,
  activeBillPage: 1,
  withdrawnBillPage: 1,
  allActiveBills: [],
  allWithdrawnBills: [],
  activeBillsLoadingPercent: 0,
  withdrawnBillsLoadingPercent: 0,
});

export const useZbcPool = () => React.useContext(ZbcPoolContext);
let msg: any = undefined;
let modal: any = undefined;
 // @ts-ignore
 let ver = window._zbc_app_ver
const useProviderZbcPool = () => {
  const ns = useNameservice();
  const backend = useBackend();
  const { accountAddress } = useWallet();
  const _modal = modal;
  const _msg = msg;
  const provider = useProvider();
  const { t } = useTranslation();
  // const wallet = useWallet()
  // const symbol = wallet.nativeBalance?.symbol;
  const zbc = useContract({
    address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.AddressZero,
    abi: ZbcAbi,
    signerOrProvider: provider,
  });

  const tradeCallbacks = {
    onSuccess() {
      if (msg) {
        msg.destroy();
        msg.success(t("the_order_has_been_sent"), 6);
      }
    },
    onMutate() {
      if (msg) {
        msg.loading(t("opening_wallet"), 0);
      }
    },
    onError(error: Error, variables: any, context: any) {
      if (msg) {
        msg.destroy();
        msg.error(t("transaction_failed"), 6);
      }
      console.log(error);
      //   if (modal) {
      //     modal.error({
      //       title: "Transaction unsuccessful",
      //       content: error.message,
      //     });
      //   }
    },
  };

  const ZbcPoolContract = {
    address: ns?.[NameserviceKeys.ZbcPool] as `0x${string}`,
    abi: ZbcAbi,
  };

  const { data: globalConfig } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address,
    functionName: "getGlobalConfig",
  });

  const { data: getActiveBill } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address && !!accountAddress && !!backend.ready,
    functionName: "getActiveBill",
    args: [accountAddress!],
  });

  const { data: getWithdrawnBill } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address && !!accountAddress && !!backend.ready,
    functionName: "getWithdrawnBill",
    args: [accountAddress!],
  });

  const zbcPrice = useMemo(
    () =>
      ethers.BigNumber.from(
        backend.ZbcPriceRaw.price?.zbcPriceFixedPoint18 || ethers.constants.Zero
      ),
    [backend.ZbcPriceRaw.price?.zbcPriceFixedPoint18]
  );

  const { data: enableInviteeBonusFee } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address && !!accountAddress && !!backend.ready && ver === "u" ,
    functionName: "enableInviteeBonusFee",
    args: [zbcPrice],
  });

  const { data: calcAdditionRewardRateInMillion } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address && !!accountAddress && !!backend.ready,
    functionName: "calcAdditionRewardRateInMillion",
    args: [accountAddress!],
  });

  const { data: getActivePersonLength } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address,
    functionName: "getActivePersonLength",
  });

  const { data: getPerson } = useContractRead({
    ...ZbcPoolContract,
    watch: true,
    enabled: !!ZbcPoolContract.address && !!accountAddress && !!backend.ready,
    functionName: "getPerson",
    args: [accountAddress!],
  });

  const { isLoading: stakeWriteIsLoading, writeAsync: stakeWrite } =
    useContractWrite({
      mode: "recklesslyUnprepared",
      ...ZbcPoolContract,
      functionName: "stake",
      ...tradeCallbacks,
    });

  const { isLoading: getRewardIsLoading, writeAsync: getReward } =
    useContractWrite({
      mode: "recklesslyUnprepared",
      ...ZbcPoolContract,
      functionName: "getReward",
      ...tradeCallbacks,
    });

  const {
    isLoading: enableInviteeBonusIsLoading,
    writeAsync: enableInviteeBonusWrite,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    ...ZbcPoolContract,
    functionName: "enableInviteeBonus",
    ...tradeCallbacks,
  });

  const { isLoading: withdrawIsLoading, writeAsync: withdraw } =
    useContractWrite({
      mode: "recklesslyUnprepared",
      ...ZbcPoolContract,
      functionName: "withdraw",
      ...tradeCallbacks,
    });

  const {
    isLoading: syncAdditionalApyIsLoading,
    writeAsync: syncAdditionalApy,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    ...ZbcPoolContract,
    functionName: "syncAdditionalApy",
    ...tradeCallbacks,
  });

  // get active and withdraw stake list

  const [activeBillPage, setActiveBillPage] = useState(1);
  const [withdrawnBillPage, setWithdrawnBillPage] = useState(1);

  // cal APY

  const [plans, setPlans]: any = useState([
    [18, "--", ethers.constants.Zero, true],
    [88, "--", ethers.constants.Zero, true],
    [188, "--", ethers.constants.Zero, true],
    [888, "--", ethers.constants.Zero, true],
  ]);

  const syncPlans = useCallback(async () => {
    try {
      let data = [];
      let planIndex = [18, 88, 188, 888];
      const oneDay = globalConfig
        ? ethers.BigNumber.from(globalConfig.oneDay).toNumber()
        : null;

      if (!oneDay) return;

      for (let index = 0; index < planIndex.length; index++) {
        const element = planIndex[index];
        const apy =
          (await zbc?.determineBillApy(BigNumber.from(element * oneDay))) ||
          ethers.constants.Zero;

        data.push([element, apy.toNumber() / 10000, apy, false]);
      }
      setPlans(data);
    } catch (error) {
      console.log(error);
    }
  }, [globalConfig, zbc]);

  useEffect(() => {
    syncPlans();
  }, [syncPlans]);

  const [allActiveBills, setAllActiveBills] = useState<any>([]);
  const [activeBillsLoadingPercent, setActiveBillsLoadingPercent] = useState(0);
  const getAllActiveBills = useCallback(async () => {
    const activeBillNumbers = getActiveBill;
    let results: any = [];
    try {
      let getActiveBill = multicall_contract({
        abi: ZbcAbi,
        address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.Zero,
        functionName: "getBill",
        percent: (percent: number) => setActiveBillsLoadingPercent(percent),
      });
      results = await getActiveBill(activeBillNumbers);
    } catch (error) {
      console.log(error);
    }

    // console.log("活跃订单开始");
    // console.log("谁 -- ZBC 入金 -- USD 入金 -- ZBC 出金 -- USD 出金");

    // results.forEach((element: any) => {
    //   console.log(
    //     element.who,
    //     element.zbcAmount.toString(),
    //     element.efficientUsd.toString(),
    //     element.cumulativeZbcTaken.toString(),
    //     element.cumulativeUsdTaken.toString()
    //   );
    // });

    setAllActiveBills(results);
  }, [getActiveBill, ns]);

  useEffect(() => {
    if (accountAddress && getActiveBill && backend.ready) {
      getAllActiveBills();
    }
  }, [accountAddress, backend.ready, getActiveBill, getAllActiveBills]);

  const [allWithdrawnBills, setAllWithdrawnBills] = useState<any>([]);
  const [withdrawnBillsLoadingPercent, setwithdrawnBillsLoadingPercent] =
    useState(0);
  const getAllWithdrawnBills = useCallback(async () => {
    const withdrawnBillNumbers = getWithdrawnBill;
    let results: any = [];
    try {
      let getWithdrawnBill = multicall_contract({
        abi: ZbcAbi,
        address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.Zero,
        functionName: "getBill",
        percent: (percent: number) => setwithdrawnBillsLoadingPercent(percent),
      });
      results = await getWithdrawnBill(withdrawnBillNumbers);
    } catch (error) {
      console.log(error);
    }

    // console.log("已提现订单开始");
    // console.log("谁 -- ZBC 入金 -- USD 入金 -- ZBC 出金 -- USD 出金");

    // results.forEach((element: any) => {
    //   console.log(
    //     element.who,
    //     element.zbcAmount.toString(),
    //     element.efficientUsd.toString(),
    //     element.cumulativeZbcTaken.toString(),
    //     element.cumulativeUsdTaken.toString()
    //   );
    // });

    setAllWithdrawnBills(results);
  }, [getWithdrawnBill, ns]);

  useEffect(() => {
    if (accountAddress && getWithdrawnBill && backend.ready) {
      
      getAllWithdrawnBills();
    }
  }, [
    accountAddress,
    backend.ready,
    getActiveBill,
    getAllActiveBills,
    getAllWithdrawnBills,
    getWithdrawnBill,
  ]);

  return {
    globalConfig,
    ZbcPoolContract,
    enableInviteeBonusFee,
    getPerson,
    stakeWriteIsLoading,
    stakeWrite,
    enableInviteeBonusIsLoading,
    enableInviteeBonusWrite,
    getActiveBill,
    getWithdrawnBill,
    calcAdditionRewardRateInMillion,
    withdraw,
    withdrawIsLoading,
    getActivePersonLength,
    syncAdditionalApy,
    syncAdditionalApyIsLoading,
    getRewardIsLoading,
    getReward,
    modal: _modal,
    plans,
    msg: _msg,
    setActiveBillPage,
    setWithdrawnBillPage,
    activeBillPage,
    withdrawnBillPage,
    allActiveBills,
    activeBillsLoadingPercent,
    allWithdrawnBills,
    withdrawnBillsLoadingPercent,
  };
};
const ZbcPoolProvider = ({ children }: { children: React.ReactNode }) => {
  const pool = useProviderZbcPool();

  const [messageApi, messageContextHolder] = message.useMessage({
    maxCount: 2,
  });
  const [modalApi, modalContextHolder] = Modal.useModal();
  msg = messageApi;
  modal = modalApi;
  return (
    <ZbcPoolContext.Provider value={pool}>
      {children}
      {messageContextHolder}
      {modalContextHolder}
    </ZbcPoolContext.Provider>
  );
};
export default ZbcPoolProvider;
