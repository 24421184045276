import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { Card, Row, Col, Button, Divider, Alert, Progress } from "antd";
import { HeaderStaking } from "../../components";
import FlexTable from "../../components/FlexTable";
import "./index.scss";
import { CustomerFooter } from "../../components";
import { useZbcPool } from "../../store/zbc-pool-context";
import { ethers } from "ethers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useWallet } from "../../store/wallet-context";
import { useBackend } from "../../store/backend";
import { NameserviceKeys } from "../../store/nameservice-context";
import { useNameservice } from "../../store/nameservice-context";
import { useProvider, useContract } from "wagmi";
import zbcAbi from "../../abi/ZBCPoolInterface";
import { formatAmount } from "../../utils/utils";
import { useInvitationCenter } from "../../store/invitation-center-context";
import { useTranslation } from "react-i18next";
import "dayjs/locale/en";
import "dayjs/locale/ms";
import "dayjs/locale/ko";
import "dayjs/locale/ru";
import "dayjs/locale/es";
import "dayjs/locale/hi";
import "dayjs/locale/vi";
import "dayjs/locale/ja";
import "dayjs/locale/ar";
import "dayjs/locale/id";
import "dayjs/locale/zh-cn";
import i18next from "i18next";
dayjs.extend(relativeTime);

const Claim: React.FC = () => {
  // @ts-ignore
  const ver = window._zbc_app_ver;
  const zbcPool = useZbcPool();
  const wallet = useWallet();
  const backend = useBackend();
  const ns = useNameservice();
  const [largerStakeText, setLargerStakeText] = useState("--");
  const [longerStakeText, setLongerStakeText] = useState("--");
  const provider = useProvider();
  const { t } = useTranslation();
  const { allActiveBills } = zbcPool;
  const zbc = useContract({
    address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.AddressZero,
    abi: zbcAbi,
    signerOrProvider: provider,
  });

  useEffect(() => {
    // console.log(i18next.language);
    if (i18next.language === "zh") {
      dayjs.locale("zh-cn");
    } else {
      dayjs.locale(i18next.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const backend_ref = useRef(backend);
  backend_ref.current = backend;
  let [symbol, setSymbol] = useState("");

  useEffect(() => {
    if (provider?.chains) {
      setSymbol(provider?.chains[0]?.nativeCurrency.symbol || "");
    }
  }, [provider.chains]);

  if (provider.chains) {
    symbol = provider?.chains[0]?.nativeCurrency.symbol;
  }
  const sort_bills = useCallback(() => {
    let longerBillStart = ethers.constants.Zero;
    let longerBill = ethers.constants.Zero;
    let largerBill = ethers.constants.Zero;

    try {
      allActiveBills?.forEach((e: any, i: number) => {
        if (e.zbcAmount) {
          const timeStart = ethers.BigNumber.from(e.lockTimestamp);
          const timeEnd = ethers.BigNumber.from(e.finishedTimestamp);
          const zbcAmount = ethers.BigNumber.from(e.zbcAmount);
          const DAY_SCALE_BN = zbcPool?.globalConfig?.oneDay
            ? ethers.BigNumber.from(60 * 60 * 24).div(
                zbcPool?.globalConfig?.oneDay
              )
            : ethers.BigNumber.from(1);

          if (zbcAmount.gte(largerBill)) {
            largerBill = zbcAmount;
          }

          if (timeEnd.sub(timeStart).gte(longerBill)) {
            longerBillStart = timeStart;
            longerBill = timeEnd.sub(timeStart);
          }

          const longerBillEndScaled = longerBillStart.add(
            longerBill.mul(DAY_SCALE_BN)
          );
          const longerStakeDays = Math.floor(
            dayjs
              .duration(
                longerBillEndScaled.toNumber() * 1000 -
                  longerBillStart.toNumber() * 1000
              )
              .asDays()
          );
          setLongerStakeText(`${longerStakeDays} Days`);
          setLargerStakeText(formatAmount(largerBill) + " " + symbol);
        }
      });
    } catch (error) {
      console.log("can't sort bills,may network error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allActiveBills, zbcPool?.globalConfig?.oneDay, symbol, i18next.language]);

  const invitationCenter = useInvitationCenter();

  useEffect(() => {
    if (wallet.accountAddress) sort_bills();
    // console.log(zbcPool);
  }, [sort_bills, wallet.accountAddress, zbcPool]);

  const updateData = async () => {
    if (zbcPool.syncAdditionalApy && !zbcPool.syncAdditionalApyIsLoading) {
      console.log("sync");
      const { price, signature } = backend.apy;
      if (!backend.ready) {
        backend.fetchData();
        return false;
      }
      zbcPool
        .syncAdditionalApy({
          recklesslySetUnpreparedArgs: [price, signature],
        })
        .catch(() => {});
    }
  };

  const [dataDiff, setDataDiff] = useState(false);

  const computeDiff = useCallback(
    (backend: any, zbcPool: any) => {
      if (
        wallet.accountAddress &&
        invitationCenter.isRegistered &&
        zbcPool?.getPerson &&
        backend.apy &&
        backend.ready
      ) {
        try {
          const price = backend?.apy?.price;
          let zepochNumber_level_a = 0;
          let zepochNumber_level_b = 0;
          let inviteeZepochNumber_level_a = 0;
          let inviteeZepochNumber_level_b = 0;
          let transactionNumber_level_a = 0;
          let transactionNumber_level_b = 0;
          // a
          if (100 <= price?.zepochNumber) zepochNumber_level_a = 3;
          else if (10 <= price?.zepochNumber) zepochNumber_level_a = 2;
          else if (1 <= price?.zepochNumber) zepochNumber_level_a = 1;

          if (100 <= price?.inviteeZepochNumber)
            inviteeZepochNumber_level_a = 3;
          else if (10 <= price?.inviteeZepochNumber)
            inviteeZepochNumber_level_a = 2;
          else if (1 <= price?.inviteeZepochNumber)
            inviteeZepochNumber_level_a = 1;

          if (188888 <= price?.transactionNumber) transactionNumber_level_a = 5;
          else if (88888 <= price?.transactionNumber)
            transactionNumber_level_a = 4;
          else if (8888 <= price?.transactionNumber)
            transactionNumber_level_a = 3;
          else if (888 <= price?.transactionNumber)
            transactionNumber_level_a = 2;
          else if (88 <= price?.transactionNumber)
            transactionNumber_level_a = 1;

          // b
          if (100 <= zbcPool.getPerson.zepochNumber.toNumber())
            zepochNumber_level_b = 3;
          else if (10 <= zbcPool.getPerson.zepochNumber.toNumber())
            zepochNumber_level_b = 2;
          else if (1 <= zbcPool.getPerson.zepochNumber.toNumber())
            zepochNumber_level_b = 1;

          if (100 <= zbcPool.getPerson.inviteeZepochNumber.toNumber())
            inviteeZepochNumber_level_b = 3;
          else if (10 <= zbcPool.getPerson.inviteeZepochNumber.toNumber())
            inviteeZepochNumber_level_b = 2;
          else if (1 <= zbcPool.getPerson.inviteeZepochNumber.toNumber())
            inviteeZepochNumber_level_b = 1;

          if (188888 <= zbcPool.getPerson.transactionNumber.toNumber())
            transactionNumber_level_b = 5;
          else if (88888 <= zbcPool.getPerson.transactionNumber.toNumber())
            transactionNumber_level_b = 4;
          else if (8888 <= zbcPool.getPerson.transactionNumber.toNumber())
            transactionNumber_level_b = 3;
          else if (888 <= zbcPool.getPerson.transactionNumber.toNumber())
            transactionNumber_level_b = 2;
          else if (88 <= zbcPool.getPerson.transactionNumber.toNumber())
            transactionNumber_level_b = 1;
          // debugger;

          if (
            zepochNumber_level_a !== zepochNumber_level_b ||
            inviteeZepochNumber_level_a !== inviteeZepochNumber_level_b ||
            transactionNumber_level_a !== transactionNumber_level_b
          ) {
            // show refresh button because oricle returns diffrent data with
            setDataDiff(true);
            return true;
          } else {
            setDataDiff(false);
            return false;
          }
        } catch (error) {
          console.log("error when compute data diff");
          setDataDiff(false);
          return false;
        }
      } else {
        setDataDiff(false);
        return false;
      }
    },
    [invitationCenter.isRegistered, wallet.accountAddress]
  );

  useEffect(() => {
    computeDiff(backend, zbcPool);
  }, [
    backend,
    backend.apy,
    computeDiff,
    invitationCenter.isRegistered,
    wallet.accountAddress,
    zbcPool,
    zbcPool.getPerson,
  ]);

  const [loadingClaim, setLoadingClaim] = useState(false);

  const ClaimableBills = useCallback(() => {
    if (zbcPool.getActiveBill && zbcPool.getActiveBill.length && allActiveBills?.length) {
      return zbcPool.getActiveBill.filter((e: any, i: number) => {
        const bill = allActiveBills[i];
        return bill.finishedTimestamp.toNumber() > Math.floor(Date.now()/1000)
      });
    }
    return [];
  }, [allActiveBills, zbcPool.getActiveBill]);

  const claim = async () => {
    setLoadingClaim(true);
    const backend = backend_ref.current;
    const { signature, price } = backend.ZbcPriceRaw;
    if (!backend.ready) {
      if (ver === "u") {
        console.log("fetch data now!");
        setTimeout(() => {
          backend.fetchData();
          claim();
        }, 5000);
        return false;
      }
    }
    if (zbcPool.getReward) {
      if (ver === "u") {
        // ver u
        await zbcPool
          .getReward({
            recklesslySetUnpreparedArgs: [price, signature, ClaimableBills()],
          })
          .catch(() => {});
      } else {
        // ver z
        await zbcPool
          .getReward({
            recklesslySetUnpreparedArgs: [ClaimableBills()],
          })
          .catch(() => {});
      }
    }
    setLoadingClaim(false);
  };

  const rd_columns = wallet.accountAddress
    ? [
        {
          key: "AdditionalAPY",
          title: t("Bonus"),
          span: 3,
          tip: "Bonus tip",
        },
        {
          key: "ZepochNodes",
          title: "Zepoch nodes",
          span: 3,
          onRefresh: dataDiff ? updateData : undefined,
          isLoading: zbcPool.syncAdditionalApyIsLoading,
        },
        {
          key: "ReferralZepochNodes",
          title: "Referral zepoch nodes",
          span: 5,
          onRefresh: dataDiff ? updateData : undefined,
          isLoading: zbcPool.syncAdditionalApyIsLoading,
        },
        {
          key: "TXN_on_Chain",
          title: "TXN on Chain",
          span: 4,
          onRefresh: dataDiff ? updateData : undefined,
          isLoading: zbcPool.syncAdditionalApyIsLoading,
        },
        {
          key: "LargerTheStake",
          title: "The Largest Stake",
          span: 5,
        },
        {
          key: "LongerTheStake",
          title: "The Longest Stake",
          span: 4,
        },
      ]
    : [];

  const rd_data = useMemo(() => {
    let rd_data: Record<string, any>[] = [{ ready: false }];
    try {
      rd_data = wallet.accountAddress
        ? [
            {
              AdditionalAPY:
                invitationCenter.isRegistered &&
                zbcPool.calcAdditionRewardRateInMillion !== undefined
                  ? formatAmount(
                      zbcPool.calcAdditionRewardRateInMillion
                        .sub(1000000)
                        .toNumber() / 10000
                    ) + " %"
                  : "--",
              ZepochNodes:
                invitationCenter.isRegistered &&
                backend?.apy?.price?.zepochNumber !== undefined
                  ? backend?.apy?.price?.zepochNumber
                  : "--",
              ReferralZepochNodes:
                invitationCenter.isRegistered &&
                backend?.apy?.price?.inviteeZepochNumber !== undefined
                  ? backend?.apy?.price?.inviteeZepochNumber
                  : "--",
              TXN_on_Chain:
                invitationCenter.isRegistered &&
                backend?.apy?.price?.transactionNumber !== undefined
                  ? backend?.apy?.price?.transactionNumber
                  : "--",
              LargerTheStake: invitationCenter.isRegistered
                ? largerStakeText
                : "--",
              LongerTheStake: invitationCenter.isRegistered
                ? longerStakeText
                : "--",
              ready: true,
            },
          ]
        : [];
    } catch (error) {
      console.log("cant't compute rd_data", error);
    }
    return rd_data;
  }, [
    backend?.apy?.price?.inviteeZepochNumber,
    backend?.apy?.price?.transactionNumber,
    backend?.apy?.price?.zepochNumber,
    invitationCenter.isRegistered,
    largerStakeText,
    longerStakeText,
    wallet.accountAddress,
    zbcPool.calcAdditionRewardRateInMillion,
  ]);

  const [awardAmount, setaWardAmount] = useState("--");
  const [awardAmountIsLoading, setAwardAmountIsLoading] = useState(false);

  const computeClaimable = useCallback(async () => {
    setAwardAmountIsLoading(true);
    setaWardAmount("--");
    const backend = backend_ref.current;
    try {
      const { signature, price } = backend.ZbcPriceRaw;
      if (ver === "u") {
        if (!backend.ready) {
          console.log("No network!");
          return false;
        }
      }

      if (ClaimableBills()?.length === 0) {
        setaWardAmount("--");
      } else {
        if (ver === "u") {
          // ver u
          const data: any = await zbc?.callStatic.getReward(
            price,
            signature,
            ClaimableBills(),
            { from: wallet.accountAddress }
          );
          setaWardAmount(
            formatAmount(data) +
              symbol +
              "(≈" +
              formatAmount(
                parseFloat(ethers.utils.formatEther(data)) *
                  parseFloat(backend.ZbcPriceFormated)
              ) +
              "$)"
          );
        } else {
          // ver z
          const data: any = await zbc?.callStatic.getReward(ClaimableBills(), {
            from: wallet.accountAddress,
          });
          setaWardAmount(formatAmount(data) + symbol);
        }
      }
    } catch (error) {
      // zbcPool.modal.error({
      //   title: "Transaction unsuccessful",
      //   content: "network Error",
      // });
      console.log("cant't computeClaimable,network down?", error);
    }
    setAwardAmountIsLoading(false);
  }, [ClaimableBills, symbol, ver, wallet.accountAddress, zbc?.callStatic]);

  useEffect(() => {
    if (
      wallet?.accountAddress &&
      invitationCenter?.isRegistered &&
      backend.ready
    )
      computeClaimable();
  }, [
    backend?.ready,
    computeClaimable,
    invitationCenter?.isRegistered,
    wallet?.accountAddress,
  ]);

  const gr_columns = [
    {
      key: "claimable",
      title: <span style={{ lineHeight: "50px" }}>{t("Claimable")}</span>,
      onRefresh:
        wallet.accountAddress && invitationCenter.isRegistered
          ? computeClaimable
          : undefined,
      span: 8,
      isLoading: awardAmountIsLoading,
    },
    {
      key: "claim",
      title: "",
      span: 6,
    },
  ];

  let gr_data: Record<string, any>[] = [{ ready: false }];

  try {
    gr_data = [
      {
        claimable: (
          <span style={{ lineHeight: "50px" }}>
            {allActiveBills?.length === 0 ? "0" : awardAmount}
          </span>
        ),
        claim: (
          <Button
            disabled={
              !wallet.accountAddress ||
              !invitationCenter.isRegistered ||
              ClaimableBills().length === 0
            }
            loading={loadingClaim}
            block
            style={{ minWidth: "120px" }}
            type="primary"
            size="large"
            onClick={claim}
          >
            {t("Claim")}
          </Button>
        ),
        ready: true,
      },
    ];
  } catch (error) {
    console.log("cant't compute gr_data", error);
  }

  return (
    <div className="stake-page claim-page">
      <HeaderStaking />
      <div className="container">
        <div style={{ padding: "30px 0" }}></div>
        {/* use components rather than fragments! */}
        <Row justify="center" style={{ padding: "30px 0 40px 0" }}>
          <Col span={24} className="card-bt40">
            <Card bordered={false} title="Rewards detail">
              {wallet.accountAddress ? (
                <>
                  {zbcPool.activeBillsLoadingPercent === 100 ? (
                    <>
                      <FlexTable
                        id={"rd"}
                        dataSource={rd_data}
                        columns={rd_columns}
                      />
                      <Divider />
                      <FlexTable
                        id={"getReword"}
                        dataSource={gr_data}
                        columns={gr_columns}
                      />
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Progress
                        strokeColor="#59D89D"
                        type="circle"
                        percent={zbcPool.activeBillsLoadingPercent}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Alert message={"No Wallet Connection"} type="success" />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <CustomerFooter></CustomerFooter>
    </div>
  );
};

export default Claim;
